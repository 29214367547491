import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Form } from '@unform/web';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup';
import Button from '../../components/Button';
import CancelButton from '../../components/CancelButton';
import FormInput from '../../components/FormInput';
import Api, { exceptionNotificationAPI } from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { Container } from './styles';
import { success_message } from '../../components/Toast';
import { AiOutlineDelete } from 'react-icons/ai'
import GetTypeInput from '../../components/FormInput/set';

const SubSectionKPIEdit = () => {
    const { id } = useParams();
    const [unitMeasureOptions, setUnitMeasureOptions] = useState();
    const [kpiOptions, setKpiOptions] = useState();
    const [reportYearOptions, setReportYearOptions] = useState();
    const [companiesOptions, setCompaniesOptions] = useState();
    const [tobaccoTypeOptions, setTobaccoTypeOptions] = useState();
    const [countriesOptions, setCountriesOptions] = useState();
    const [subSectionKPIsOptions, setSubSectionKPIsOptions] = useState();
    const [customOptions, setCustomOptions] = useState();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isKPILoading, setIsKPILoading] = useState(true);
    const [KPIsOptions, setKPIsOptions] = useState([]);
    const [selectedReportYearID, setSelectedReportYearID] = useState(0);
    const [selectedKPIID, setSelectedKPIID] = useState(0);
    const [selectedColumnRowID, setSelectedColumnRowID] = useState(0);
    const [columnRowsOptions, setColumnRowsOptions] = useState([]);
    const [selectCustomValue, setSelectCustomValue] = useState([]);
    const [unitMeasureList, setUnitMeasureList] = useState([]);
    const [selectCustomInput, setSelectCustomInput] = useState("");
    const [multipleValues, setMultipleValues] = useState([]);

    const [disabledCustomSelect, setDisabledCustomSelect] = useState(true);
    const formRef = useRef(null);
    const [data, setData] = useState(null);

    const booleanOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const toleranceTypeOptions = [
        { value: 1, text: "Range Type" },
        { value: 2, text: "Greater Than" },
        { value: 3, text: "Lower Than" },
        { value: 4, text: "Equal To" },
        { value: 5, text: "Greater Than or Equal To" },
        { value: 6, text: "Lower Than or Equal To" },
        { value: 7, text: "Custom" },
        { value: 8, text: "Time" },
    ];

    const quantQualOptions = [
        { value: "Quant", text: "Quant" },
        { value: "Qual", text: "Qual" }
    ];

    const StandardTypeOptions = [
        { value: "", text: "Select an option" },
        { value: 1, text: "Variation" },
        { value: 2, text: "Between" },
        { value: 3, text: "Reference" },
        { value: 4, text: "SelectOption" }
    ];

    const inputCalcOptions = [
        { value: "Calculation", text: "Calculation" },
        { value: "Input", text: "Input" },
        { value: "DisplayText", text: "DisplayText" }
    ];

    const EvidenceCalculationMethodOptions = [
        { value: "SUM", text: "SUM" },
        { value: "COUNT", text: "COUNT" },
        { value: "AVG", text: "AVG" }
    ]

    const evidenceAndDescriptionOptions = [
        { value: "Non-mandatory", text: "Non-mandatory" },
        { value: "Mandatory", text: "Mandatory" }
    ];

    useEffect(() => {
        setIsLoading(true);
        loadData();
        loadTobaccoType()
        loadCountries()
        setIsLoading(false)
    }, []);

    const handleToleranceChange = (form) => {
        const toleranceTypeSelect = form.getFieldRef('toleranceType').current;
        const lowerToleranceField = form.getFieldRef('lowerTolerance').current;
        const upperToleranceField = form.getFieldRef('upperTolerance').current;
        const toleranceKPILinkedRefNumberSelect = form.getFieldRef('toleranceKPILinkedRefNumber').current;
        const alertMessageField = form.getFieldRef('alertMessage').current;

        lowerToleranceField.disabled = toleranceTypeSelect.value == 1 ? false : true;
        lowerToleranceField.value = "";

        upperToleranceField.disabled = toleranceTypeSelect.value == 1 ? false : true;
        upperToleranceField.value = "";

        toleranceKPILinkedRefNumberSelect.disabled = toleranceTypeSelect.value > 1 ? false : true;
        toleranceKPILinkedRefNumberSelect.value = "";

        alertMessageField.disabled = toleranceTypeSelect.value != 0 ? false : true;
        alertMessageField.value = "";
    }

    const handleStandardType = (form) => {
        const standardTypeSelect = form.getFieldRef('type').current;
        const lowerStandardField = form.getFieldRef('lowerValue').current;
        const upperStandardField = form.getFieldRef('upperValue').current;
        const referenceValueField = form.getFieldRef('referenceValue').current;
        const varianceField = form.getFieldRef('variation').current;

        if(standardTypeSelect.value == 1) {
            varianceField.disabled = false;
            varianceField.value = "";
            lowerStandardField.disabled = true
            lowerStandardField.value = null;
            upperStandardField.disabled = true
            upperStandardField.value = null;
            referenceValueField.disabled = false
            referenceValueField.value = "";
        }
        else if(standardTypeSelect.value == 2) {
            varianceField.disabled = true;
            varianceField.value = null;
            lowerStandardField.disabled = false
            lowerStandardField.value = "";
            upperStandardField.disabled = false
            upperStandardField.value = "";
            referenceValueField.disabled = true
            referenceValueField.value = null;
        }
        else if(standardTypeSelect.value == 3) {
            varianceField.disabled = true;
            varianceField.value = null;
            lowerStandardField.disabled = true
            lowerStandardField.value = null;
            upperStandardField.disabled = true
            upperStandardField.value = null;
            referenceValueField.disabled = false
            referenceValueField.value = "";
        }
        else if(standardTypeSelect.value == 4) {
            varianceField.disabled = true;
            varianceField.value = null;
            lowerStandardField.disabled = true
            lowerStandardField.value = null;
            upperStandardField.disabled = true
            upperStandardField.value = null;
            referenceValueField.disabled = true
            referenceValueField.value = null;
        }

    }

    const handleChangeKPIID = (e) => {
        setSelectedKPIID(e.getFieldRef("kpiid").current.value);
    }

    const handleChangeReportYearID = (e) => {
        setSelectedReportYearID(e.getFieldRef("reportYearID").current.value);
    }

    const handleChangeColumnRowID = (e) => {
        setSelectedColumnRowID(e.getFieldRef("columnRowID").current.value);
    }

    const fields = [
        {
            label: "Report Year",
            name: "reportYearID",
            type: "select",
            options: reportYearOptions,
            onChanged: handleChangeReportYearID
        },
        {
            label: "KPI",
            name: "kpiid",
            type: "select",
            options: kpiOptions,
            onChanged: handleChangeKPIID
        },
        {
            label: "ColumnRow",
            name: "columnRowID",
            type: "select",
            options: columnRowsOptions,
            onChanged: handleChangeColumnRowID
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: booleanOptions,
            required: true,
        },
        {
            label: "Is Action Plan",
            name: "isActionPlan",
            type: "select",
            options: booleanOptions,
            required: true,
        },
        {
            label: "Mandatory",
            name: "mandatory",
            type: "select",
            options: booleanOptions,
            required: true,
        },
        {
            label: "Answer Type KPI",
            name: "unitMeasureID",
            type: "select",
            options: unitMeasureOptions,
            placeholder: { value: "", text: "" },
            required: true,
        },
        {
            label: "Ref Number",
            name: "refNumber",
            type: "number",
            required: true,
        },
        {
            label: "Options (custom)",
            name: "customOptions",
            type: "selectcustom",
            required: false,
        },
        {
            label: "Companies to exclude",
            name: "subSectionKPICompany",
            type: "selectcustom",
            required: false,
            options: companiesOptions,
            multiple: true
        },
        {
            label: "Input/Calculation",
            name: "inputCalc",
            type: "select",
            options: inputCalcOptions,
            required: true,
        },
        {
            label: "Calculation",
            name: "calculation",
            type: "input", //Tem uma validação diferente por que os campos vão estar em linha
            required: false,
        },
        {

            label: "Tolerance Type",
            name: "toleranceType",
            type: "select",
            options: toleranceTypeOptions,
            required: false,
            onChanged: handleToleranceChange,
            placeholder: { value: "0", text: "No tolerance" }
        },
        {
            label: "Lower Tolerance",
            name: "lowerTolerance",
            type: "number",
            disabled: true,
            required: false
        },
        {
            label: "Upper Tolerance",
            name: "upperTolerance",
            type: "number",
            disabled: true,
            required: false
        },
        {
            label: "KPI Linked (Tolerance)",
            name: "toleranceKPILinkedRefNumber",
            type: "input",
            disabled: true,
            required: false
        },
        {
            label: "Alert Message (Tolerance)",
            name: "alertMessage",
            type: "textarea",
            disabled: true,
            required: false
        },
        {
            label: "Input Message",
            name: "inputMessage",
            type: "textarea",
            required: false
        },
        {
            label: "Evidence",
            name: "evidence",
            type: "select",
            options: evidenceAndDescriptionOptions,
            required: false
        },
        {
            label: "Comment",
            name: "comment",
            type: "select",
            options: evidenceAndDescriptionOptions,
            required: false
        },
        {
            label: "Standard Validation Type",
            name: "type",
            type: "select",
            options: StandardTypeOptions,
            required: false,
            onChanged: handleStandardType
        },
        {
            label: "Reference Value",
            name: "referenceValue",
            type: "input",
            disabled: true,
            required: false
        },
        {
            label: "Lower Value",
            name: "lowerValue",
            type: "input",
            disabled: true,
            required: false
        },
        {
            label: "Upper Value",
            name: "upperValue",
            type: "input",
            disabled: true,
            required: false
        },
        {
            label: "Variation",
            name: "variation",
            type: "input",
            disabled: true,
            required: false
        },
    ]


    const [SubSectionKPIConditionalfields, setSubSectionKPIConditionalfields] = useState([

        {
            label: "Value answer",
            name: "value",
            type: "input",
            required: true
        },
        {
            label: "Block when not answer",
            name: "blockWhenNotAnswer",
            type: "select",
            options: booleanOptions,
            required: true,
        },
        {
            label: "Sub Section KPI ",
            name: "subSectionKPIID",
            type: "select",
            required: true,
            options: subSectionKPIsOptions,
            multiple: true
        },

    ]);


    useEffect(() => {
        setIsLoading(true)
        loadData().then((result) => {
            if (data === null && id != null && !isLoading) {
                loadDataEdit();
            }
        });
        setIsLoading(false)

    }, [id]);

    useEffect(() => {
        if (selectedKPIID != 0 && selectedColumnRowID != 0) {
            Api.get(`SubSectionKPI/getColumnRows?kpiId=${selectedKPIID}`).then((result) => {

                if (result && result.data && result.data.response.length > 0) {
                    const columnRows = result.data.response;
                    const options = columnRows.map(item => ({ value: item.columnRowID, text: item.name }));

                    setColumnRowsOptions(options);
                    document.getElementById('columnRowID').value = selectedColumnRowID;

                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }, [selectedKPIID, selectedColumnRowID]);

    const loadTobaccoType = async () => {
        await Api.get('TobaccoType/GetAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.tobaccoTypeID, text: item.name, hidden: false }));

                setTobaccoTypeOptions(options)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }
    const loadCountries = async () => {
        await Api.get('Company/getAllCountries').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.countryID, text: item.name }));

                setCountriesOptions(options)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadData = async () => {
        setIsKPILoading(true)
        await Api.get('ReportYear/getAll').then((result) => {
            if (result && result.data) {
                var options = result.data.response.map(item => ({ value: item.reportYearID, text: `${item.year}` }));

                setReportYearOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        await Api.get('UnitMeasure/getAll').then((result) => {
            if (result && result.data) {
                setUnitMeasureList(result.data.response)
                const options = result.data.response.map(item => ({ value: item.unitMeasureID, text: item.description + " - " + item.type }));

                setUnitMeasureOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.companyID, label: `${item.name}` }));

                setCompaniesOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const getObjects = (currentObject, fragments) => {
        if (fragments.length > 0) {
            var object = currentObject[fragments[0]];

            if (object != null) {
                fragments.shift();
                return getObjects(object, fragments);
            }
            else {
                return "";
            }
        }

        return currentObject;
    }


    const removeRule = (index) => {
        let dataToChange = data

        if (dataToChange.subSectionKPIConditionalBlocks[index].subSectionKPIConditionalBlockRuleID) {
            dataToChange.subSectionKPIConditionalBlocks[index].isDeleted = true
        } else {
            dataToChange.subSectionKPIConditionalBlocks.splice(index, 1)
        }


        setData({ ...dataToChange })
        /*let valueObjectToChange = valuesObject;
        let valuesToChange = valueObjectToChange.find(e => e.columnRowID == column.columnRowID)
        if (valuesToChange) {
            valuesToChange.subSectionKPIConditionalBlocks.splice(colIndex, 1)
            valueObjectToChange[indexToChange] = valuesToChange;
            setValuesObject([...valueObjectToChange])
        }*/

    }

    const loadKPIs = async () => {
        await Api.get(`KPI/getAllByReportYearID?reportYearID=${selectedReportYearID}`).then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.kpiid, text: item.description }));
                setKpiOptions(options);
                setSelectedKPIID(data.kpiid)
                document.getElementById('kpiid').value = data.kpiid;
                setIsKPILoading(false)

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadSubSectionKPIs = async () => {
        Api.get(`SubSectionKPI/getAll?reportYearID=${selectedReportYearID}`).then((result) => {
            if (result && result.data) {
                let options = result.data.response.map(item => ({ value: item.subSectionKPIID, label: item.refNumber }));

                options = [{ value: "", text: "Select a SubSection KPI" }, ...options];

                setSubSectionKPIsOptions(options);
                //setSelectedKPIID(options.length > 0 ? options[0].value : 0);
                //setShowKPISelect(true);
                // console.log(kpiSelect);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }



    useEffect(() => {
        if (selectedReportYearID != 0 && data != null) {
            loadKPIs()
            loadSubSectionKPIs()
        }
    }, [selectedReportYearID, data]);

    useEffect(() => {
        if (subSectionKPIsOptions && subSectionKPIsOptions.length > 0) {

            let toChange = SubSectionKPIConditionalfields;
            toChange[2].options = subSectionKPIsOptions


            setSubSectionKPIConditionalfields([...toChange])
            //TO DO
        }
    }, [subSectionKPIsOptions]);

    useEffect(() => {
        if (unitMeasureList?.length > 0 && data != null) {
            let unit = unitMeasureList.find(e => e.unitMeasureID == document.getElementById("unitMeasureID").value)
            let typeInput = GetTypeInput(unit.unitMeasureID, unit.type)
            let changed = SubSectionKPIConditionalfields
            changed[0].unitMeasure = typeInput
            setSubSectionKPIConditionalfields([...changed])
        }
    }, [unitMeasureList, data]);

    const loadDataEdit = () => {
        // if(kpiOptions != null) {
        Api.get(`subSectionKPI/get?id=${id}`).then((result) => {
            if (result && result.data) {
                var dataObj = {};
                var companyOptions = [];
                var groupOptions = [];

                fields.forEach(f => {
                    if (f.name == "sectionCompany" || f.name == "subSectionKPICompany") {
                        if (result.data.response[f.name] != null && result.data.response[f.name].length > 0) {
                            var dataMultiple = result.data.response[f.name].map((sc) => {
                                var data = {
                                    value: sc.companyID,
                                    label: sc.company.name
                                };
                                return data;
                            });
                            setMultipleValues(dataMultiple);
                        }
                    } else if (f.name == "customOptions") {
                        if (result.data.response["customOptions"] != null) {
                            var exist = result.data.response["customOptions"].indexOf(";") > -1;
                            if (exist) {
                                var dataMultiple = result.data.response["customOptions"].split(";");
                                var newDataMulti = dataMultiple.map((e) => {
                                    var newData = {
                                        "value": e,
                                        "label": e
                                    }
                                    return newData;
                                });

                                var newDataMultiSelect = result.data.response["customOptions"]



                                if (result.data.response["unitMeasureID"].toUpperCase() == 'BFB810A1-758C-4623-BE44-82CA1D94F583')
                                    setDisabledCustomSelect(false);
                                else
                                    setDisabledCustomSelect(true);

                                setCustomOptions(newDataMultiSelect)
                                setSelectCustomValue(newDataMulti);
                            } else {
                                if (result.data.response["unitMeasureID"].toUpperCase() == 'BFB810A1-758C-4623-BE44-82CA1D94F583')
                                    setDisabledCustomSelect(false);
                                else
                                    setDisabledCustomSelect(true);

                                setSelectCustomValue([]);
                            }
                        } else {
                            if (result.data.response["unitMeasureID"].toUpperCase() == 'BFB810A1-758C-4623-BE44-82CA1D94F583')
                                setDisabledCustomSelect(false);
                            else
                                setDisabledCustomSelect(true);

                            setSelectCustomValue([]);
                        }
                    } else if (f.name == "reportYearID") {
                        if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }
                        else {
                            dataObj[f.name] = result.data.response[f.name];
                        }

                        setSelectedReportYearID(result.data.response[f.name]);
                    } else if (f.name == "kpiid" && !isKPILoading) {
                        if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }
                        else {
                            dataObj[f.name] = result.data.response[f.name];
                        }

                        setSelectedKPIID(result.data.response[f.name]);
                    }
                    else if (f.name == "columnRowID") {
                        if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }
                        else {
                            dataObj[f.name] = result.data.response[f.name];
                        }
                        setSelectedColumnRowID(result.data.response[f.name]);
                    } else {
                        if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }
                        else {
                            dataObj[f.name] = result.data.response[f.name] != undefined ? result.data.response[f.name] : "";
                        }
                    }
                });


                dataObj.subSectionKPIConditionalBlocks = result.data.response.subSectionKPIConditionalBlocks

                formRef.current.setData(dataObj);
                setData(dataObj);

                //When access CRUD form to Edit an existent KPI

                if (document.getElementById('toleranceType').value == 1) {
                    document.getElementById('lowerTolerance').disabled = false;
                    document.getElementById('upperTolerance').disabled = false;
                } else if (document.getElementById('toleranceType').value > 1) {
                    document.getElementById('toleranceKPILinkedRefNumber').disabled = false;
                    document.getElementById('alertMessage').disabled = false;
                }


                if (document.getElementById('type').value == 1) {
                    document.getElementById('lowerValue').disabled = true;
                    document.getElementById('upperValue').disabled = true;
                    document.getElementById('referenceValue').disabled = false;
                    document.getElementById('variation').disabled = false;
                } else if (document.getElementById('type').value == 2) {
                    document.getElementById('lowerValue').disabled = false;
                    document.getElementById('upperValue').disabled = false;
                    document.getElementById('referenceValue').disabled = true;
                    document.getElementById('variation').disabled = true;
                }else if (document.getElementById('type').value == 3) {
                    document.getElementById('lowerValue').disabled = true;
                    document.getElementById('upperValue').disabled = true;
                    document.getElementById('referenceValue').disabled = false;
                    document.getElementById('variation').disabled = true;
                } else if (document.getElementById('type').value == 4) {
                    document.getElementById('lowerValue').disabled = true;
                    document.getElementById('upperValue').disabled = true;
                    document.getElementById('referenceValue').disabled = true;
                    document.getElementById('variation').disabled = true;
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const setAnswersRules = (value, colIndex, field) => {
        let valuesToChange = data;
        if (valuesToChange) {
            valuesToChange.subSectionKPIConditionalBlocks[colIndex][field] = value
            setData({ ...valuesToChange })
        }

    }


    const handleSubmit = async (dataForm) => {
        try {
            var obj = {}
            var postObj = {};
            const submitUrl = `subSectionKPI/${(id != null) ? "edit" : "new"}`;

            postObj["subSectionKPIID"] = id;
            postObj["subSectionKPIConditionalBlocks"] = data.subSectionKPIConditionalBlocks

            fields.map(f => {
                if (f.required !== undefined && f.required === false) {
                    return null;
                } else {
                    return obj[f.name] = Yup.string().required(`${f.label} is required`);
                }

            })

            const schema = Yup.object().shape(obj);

            await schema.validate(dataForm, {
                abortEarly: false,
            });

            fields.map(f => {
                return postObj[f.name] = dataForm[f.name]
            })

            // FIXO, CUIDAR (CUSTOM OPTIONS)
            if (selectCustomValue.length > 0) {
                postObj.customOptions = selectCustomValue.map((e) => { return e.value }).join(';');
            }

            // FIXO, CUIDAR (SECTION COMPANY)
            if (multipleValues.length > 0) {
                var formColumn = "subSectionKPICompany";
                postObj[formColumn] = multipleValues.map((m) => {
                    var dataForm = {
                        companyID: m.value
                    }
                    return dataForm;
                });
            }

            await Api.post(submitUrl, postObj);

            history.push(`/subSectionKPI`);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);
                formRef.current.setErrors(errors);
                return;
            }
            exceptionNotificationAPI(error);
        }
    }

    return (
        !isLoading ?
            <Container className="container">
                <div>
                    <h3>{(id != null) ? "Edit" : "New"} {"subSectionKPI"}</h3>
                    <Form onSubmit={handleSubmit} ref={formRef}>
                        <table className="table table-striped">
                            <tbody>
                                {
                                    fields.map((f, i) => {
                                        if (f.type === "selectcustom") {
                                            return <>
                                                <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                    <td>
                                                        <label>
                                                            {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {f.name == "subSectionKPICompany" ?
                                                            <Select
                                                                name={f.name}
                                                                id={f.name}
                                                                options={companiesOptions}
                                                                value={multipleValues}
                                                                isMulti
                                                                placeholder="Select one or more companies..."
                                                                closeMenuOnSelect={false}
                                                                onChange={(a) => setMultipleValues(a)}
                                                            />
                                                            :
                                                            <CreatableSelect
                                                                name="customOptions"
                                                                id="customOptions"
                                                                isDisabled={disabledCustomSelect}
                                                                components={{ DropdownIndicator: null }}
                                                                isClearable={true}
                                                                isMulti
                                                                menuIsOpen={false}
                                                                placeholder="Type something and press enter..."
                                                                onChange={(e) => {
                                                                    setSelectCustomValue(e);
                                                                }}
                                                                onInputChange={(e) => setSelectCustomInput(e)}
                                                                onKeyDown={(e) => {
                                                                    switch (e.key) {
                                                                        case 'Enter':
                                                                        case 'Tab':
                                                                            if (selectCustomInput == "")
                                                                                return;
                                                                            var newData = {
                                                                                "value": selectCustomInput,
                                                                                "label": selectCustomInput
                                                                            }
                                                                            var newArray = [...selectCustomValue, newData];
                                                                            setSelectCustomInput("");
                                                                            setSelectCustomValue(newArray);
                                                                            var newCustomOptions = ""
                                                                            newArray.map((option, i) => {
                                                                                if (i == newArray.length - 1) {
                                                                                    newCustomOptions += option.value
                                                                                } else {
                                                                                    newCustomOptions += option.value + ";"
                                                                                }
                                                                            })
                                                                            setCustomOptions(newCustomOptions)
                                                                            e.preventDefault();
                                                                    }
                                                                }}
                                                                value={selectCustomValue}
                                                                inputValue={selectCustomInput}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                        else if (f.type === "select") {
                                            return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td width="25%">
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td width="75%">
                                                    {!f.multiple ?
                                                        <FormInput
                                                            name={f.name}
                                                            id={f.name}
                                                            checked={f.checked}
                                                            inputType={f.type}
                                                            placeholder={f.placeholder}
                                                            options={f.options}
                                                            disabled={f.disabled ? f.disabled : false}
                                                            defaultValue={f.value ?? ""}
                                                            onChange={(a) => {
                                                                if (f.name == "unitMeasureID" && a.target.value.toUpperCase() != "BFB810A1-758C-4623-BE44-82CA1D94F583") {
                                                                    let unit = unitMeasureList.find(e => e.unitMeasureID == a.target.value)
                                                                    let typeInput = GetTypeInput(unit.unitMeasureID, unit.type)
                                                                    let changed = SubSectionKPIConditionalfields
                                                                    changed[0].unitMeasure = typeInput
                                                                    setSubSectionKPIConditionalfields([...changed])
                                                                    setDisabledCustomSelect(true);
                                                                    setSelectCustomValue([]);
                                                                } else if (f.name == "unitMeasureID" && (a.target.value.toUpperCase() == "BFB810A1-758C-4623-BE44-82CA1D94F583")) {
                                                                    let unit = unitMeasureList.find(e => e.unitMeasureID == a.target.value)
                                                                    let typeInput = GetTypeInput(unit.unitMeasureID, unit.type)
                                                                    let changed = SubSectionKPIConditionalfields
                                                                    changed[0].unitMeasure = typeInput
                                                                    setSubSectionKPIConditionalfields([...changed])
                                                                    setDisabledCustomSelect(false);
                                                                    //setSelectCustomValue([]);
                                                                } else if (f.name != "unitMeasureID" && (document.getElementById("unitMeasureID").value.toUpperCase() == "BFB810A1-758C-4623-BE44-82CA1D94F583")) {
                                                                    setDisabledCustomSelect(false);
                                                                } else {
                                                                    setDisabledCustomSelect(true);
                                                                }
                                                                if (f.onChanged != null) {
                                                                    f.onChanged(formRef.current)
                                                                }
                                                                return;
                                                            }}
                                                        />
                                                        :
                                                        <Select
                                                            options={f.options.map((o) => {
                                                                var data = {
                                                                    value: o.value,
                                                                    label: o.text
                                                                }
                                                                return data;
                                                            })}
                                                            value={multipleValues}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            onChange={(a) => setMultipleValues(a)}
                                                        />}
                                                </td>
                                            </tr>
                                        } else {
                                            if (id !== undefined && f.editable !== undefined && f.editable === false) {
                                                return <></>
                                            } else {
                                                return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                    <td width="25%">
                                                        <label>
                                                            {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                        </label>
                                                    </td>
                                                    <td width="75%">
                                                        <FormInput
                                                            className="form-control"
                                                            name={f.name}
                                                            id={f.name}
                                                            disabled={f.disabled ? f.disabled : false}
                                                            checked={f.checked}
                                                            inputType={f.type}
                                                            defaultValue={f.value ?? ""}
                                                        />
                                                    </td>
                                                </tr>
                                            }
                                        }
                                    })
                                }

                            </tbody>
                        </table>
                        <div className='mt-5'>
                            {

                                <a className="addnewLine btn-primary" style={{ padding: '0.5rem 1rem', textDecoration: 'none', cursor: 'pointer' }} onClick={() => {
                                    if (subSectionKPIsOptions) {
                                        let changedData = data
                                        changedData.subSectionKPIConditionalBlocks.push({
                                            value: "",
                                            subSectionKPIIDs: ""
                                        })
                                        setData({ ...data })
                                    }
                                }}>Add New Conditional Block</a>

                            }
                        </div>
                        <table className="table table-striped my-5">
                            <thead>
                                <tr><th scope="col">{"Index"}</th>
                                    {SubSectionKPIConditionalfields.map((cs) => {
                                        return (
                                            <th scope="col text-center">{cs.label}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.subSectionKPIConditionalBlocks && data.subSectionKPIConditionalBlocks.length > 0 && subSectionKPIsOptions?.length > 0 ?
                                        data.subSectionKPIConditionalBlocks.filter(e => !e.isDeleted)?.map((col, colinx) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <label>
                                                            {colinx + 1}
                                                        </label>
                                                    </td>
                                                    {SubSectionKPIConditionalfields.map((c, ind) => {
                                                        return (
                                                            ind == 0 ? <td scope="row">
                                                                {
                                                                    c.unitMeasure && c.unitMeasure.inputType == 'selectfuel' ?

                                                                        <FormInput
                                                                            //options={fuelTypeOptions}
                                                                            name={c.name}
                                                                            id={c.name + ind}
                                                                            inputType={'input'}
                                                                            autoComplete="off"
                                                                            value={col.value != null ? col.value : ""}
                                                                            onChange={(f) => {
                                                                                setAnswersRules(f.target.value, colinx, "value")
                                                                            }}
                                                                        />

                                                                        :
                                                                        (c.unitMeasure && c.unitMeasure.inputType == 'selectcustom' && customOptions ?
                                                                            <FormInput
                                                                                customOptions={customOptions}
                                                                                name={c.name}
                                                                                id={c.name + ind}
                                                                                inputType={c.unitMeasure.inputType}
                                                                                autoComplete="off"
                                                                                value={col.value != null ? col.value : ""}
                                                                                onChange={(f) => {
                                                                                    setAnswersRules(f.target.value, colinx, "value")
                                                                                }}
                                                                            />
                                                                            :
                                                                            <FormInput
                                                                                name={c.name}
                                                                                id={c.name + ind}
                                                                                autoComplete="off"
                                                                                value={col.value != null ? col.value : ""}
                                                                                //title={moduleObject.isEditable && value != null ? subSectionKPITarget.kpi.inputMessage : ""}
                                                                                inputType={c.unitMeasure && c.unitMeasure.inputType != null && c.unitMeasure.inputType != "" ? c.unitMeasure.inputType : "text"}
                                                                                //className={moduleObject.isEditable ? "" : "read-only"}
                                                                                //customOptions={subSectionKPITarget.kpi.customOptions}
                                                                                //placeholder={c.unitMeasure.inputType == "selectcustom" ? { value: "", text: "Select an option" } : null}
                                                                                //disabled={moduleObject.isEditable && country != null ? false : true}
                                                                                onChange={(f) => {
                                                                                    setAnswersRules(f.target.value, colinx, "value")
                                                                                }
                                                                                }

                                                                            />
                                                                        )
                                                                }
                                                            </td>
                                                                :
                                                                ind == 1 ?
                                                                    <td style={{ width: "20rem" }}>
                                                                        <FormInput
                                                                            name={c.name}
                                                                            id={c.name + ind}
                                                                            disabled={c.disabled ? c.disabled : false}
                                                                            value={col.blockWhenNotAnswer != null ? col.blockWhenNotAnswer : ""}
                                                                            inputType={c.type}
                                                                            options={c.options}
                                                                            onChange={(f) => {
                                                                                setAnswersRules(f.target.value, colinx, "blockWhenNotAnswer")
                                                                            }
                                                                            }
                                                                        />
                                                                    </td>
                                                                    :


                                                                    <td style={{ width: "40rem" }}>


                                                                        <Select
                                                                            options={c.options}
                                                                            value={col.subSectionKPIIDs}
                                                                            isMulti
                                                                            closeMenuOnSelect={false}
                                                                            onChange={(a) => setAnswersRules(a, colinx, "subSectionKPIIDs")}
                                                                        />
                                                                    </td >



                                                        )
                                                    })}
                                                    {colinx != 0 ? <td><AiOutlineDelete size={20} onClick={(e) => { removeRule(colinx) }/*handleRemoveColumnRowInputs(e.target.value, colinx)*/} /></td> : <></>}
                                                </tr>
                                            )
                                        })

                                        : <></>
                                }
                            </tbody>
                        </table>
                        <div className="d-flex flex-row mt-2">
                            <CancelButton />
                            <Button type="submit">Save</Button>
                        </div>
                    </Form>
                </div>
            </Container>
            : <></>
    );
}

export default SubSectionKPIEdit;
