import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Form } from '@unform/web';
import { GoChevronDown } from 'react-icons/go';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup';
import Button from '../../components/Button';
import CancelButton from '../../components/CancelButton';
import FormInput from '../../components/FormInput';
import TableLoading from '../../components/TableLoading';
import { error_message, success_message } from '../../components/Toast';
import Api, { exceptionNotificationAPI } from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { Accordion, Container } from './styles';
import GetTypeInput from '../../components/FormInput/set';
import { AiOutlineDelete } from 'react-icons/ai'


const SubSectionKPIForm = () => {
    const [KPIsOptions, setKPIsOptions] = useState([]);
    const [reportYearOptions, setReportYearOptions] = useState([]);
    const [selectedReportYearID, setSelectedReportYearID] = useState(0);
    const [selectedKPIID, setSelectedKPIID] = useState(0);
    const [unitMeasureOptions, setUnitMeasureOptions] = useState();
    const [tobaccoTypeOptions, setTobaccoTypeOptions] = useState();
    const [countriesOptions, setCountriesOptions] = useState();
    const [companiesOptions, setCompaniesOptions] = useState();
    const [unitMeasureList, setUnitMeasureList] = useState([]);
    const [subSectionKPIsOptions, setSubSectionKPIsOptions] = useState();
    const { id } = useParams();
    const history = useHistory();
    const [isColumnsLoading, setIsColumnsLoading] = useState(false);
    const [showKPISelect, setShowKPISelect] = useState(false);
    const [showColumnRowSelect, setShowColumnRowSelect] = useState(false);
    const [selectCustomValue, setSelectCustomValue] = useState([]);
    const [selectCustomInput, setSelectCustomInput] = useState("");
    const [subSectionKPIData, setSubSectionKPIData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [columnRows, setColumnRows] = useState([]);
    const [columnRowsOptions, setColumnRowsOptions] = useState([]);
    const [selectedColumnRows, setSelectedColumnRows] = useState([]);
    const [selectedAccordionItems, setSelectedAccordionItems] = useState([]);
    const [multipleValues, setMultipleValues] = useState([]);
    const formRef = useRef(null);
    const [valuesObject, setValuesObject] = useState({
        answers: [],
    });

    const filterColumnRow = (index) => {
        if (selectedAccordionItems.includes(index)) {
            setSelectedAccordionItems(selectedAccordionItems.filter((x) => x != index))
        } else {
            setSelectedAccordionItems([...selectedAccordionItems, index])
        }
    }


    const getObjects = (currentObject, fragments) => {
        if (fragments.length > 0) {
            var object = currentObject[fragments[0]];

            if (object != null) {
                fragments.shift();
                return getObjects(object, fragments);
            } else {
                return "";
            }
        }

        return currentObject;
    }

    const handleToleranceChange = (column, field) => {
        var seletorTT = "#form-input-" + column.columnRowID + "-toleranceType";
        var seletorLT = "#form-input-" + column.columnRowID + "-lowerTolerance";
        var seletorUT = "#form-input-" + column.columnRowID + "-upperTolerance";
        var seletorTKPI = "#form-input-" + column.columnRowID + "-toleranceKPILinkedRefNumber";
        var seletorAT = "#form-input-" + column.columnRowID + "-alertMessage";

        const toleranceTypeSelect = document.querySelector(seletorTT)
        const lowerToleranceField = document.querySelector(seletorLT)
        const upperToleranceField = document.querySelector(seletorUT)
        const toleranceKPILinkedRefNumberSelect = document.querySelector(seletorTKPI)
        const alertMessageField = document.querySelector(seletorAT)

        lowerToleranceField.disabled = toleranceTypeSelect.value == 1 ? false : true;
        lowerToleranceField.value = "";

        upperToleranceField.disabled = toleranceTypeSelect.value == 1 ? false : true;
        upperToleranceField.value = "";

        toleranceKPILinkedRefNumberSelect.disabled = toleranceTypeSelect.value > 1 ? false : true;
        toleranceKPILinkedRefNumberSelect.value = "";

        alertMessageField.disabled = toleranceTypeSelect.value != 0 ? false : true;
        alertMessageField.value = "";
    }

    const handleStandardType = (column, field) => {
        var seletorTT = "#form-input-" + column.columnRowID + "-type";
        var seletorLT = "#form-input-" + column.columnRowID + "-lowerValue";
        var seletorUT = "#form-input-" + column.columnRowID + "-upperValue";
        var seletorTKPI = "#form-input-" + column.columnRowID + "-referenceValue";
        var seletorAT = "#form-input-" + column.columnRowID + "-variation";

        const standardTypeSelect = document.querySelector(seletorTT)
        const lowerStandardField = document.querySelector(seletorLT)
        const upperStandardField = document.querySelector(seletorUT)
        const referenceValueField = document.querySelector(seletorTKPI)
        const varianceField = document.querySelector(seletorAT)

        if(standardTypeSelect.value == 1) {
            varianceField.disabled = false;
            varianceField.value = "";
            lowerStandardField.disabled = true
            lowerStandardField.value = null;
            upperStandardField.disabled = true
            upperStandardField.value = null;
            referenceValueField.disabled = false
            referenceValueField.value = "";
        }
        else if(standardTypeSelect.value == 2) {
            varianceField.disabled = true;
            varianceField.value = null;
            lowerStandardField.disabled = false
            lowerStandardField.value = "";
            upperStandardField.disabled = false
            upperStandardField.value = "";
            referenceValueField.disabled = true
            referenceValueField.value = null;
        }
        else if(standardTypeSelect.value == 3) {
            varianceField.disabled = true;
            varianceField.value = null;
            lowerStandardField.disabled = true
            lowerStandardField.value = null;
            upperStandardField.disabled = true
            upperStandardField.value = null;
            referenceValueField.disabled = false
            referenceValueField.value = "";
        }
        else if(standardTypeSelect.value == 4) {
            varianceField.disabled = true;
            varianceField.value = null;
            lowerStandardField.disabled = true
            lowerStandardField.value = null;
            upperStandardField.disabled = true
            upperStandardField.value = null;
            referenceValueField.disabled = true
            referenceValueField.value = null;
        }

    }

    const columnRowSelect = {
        label: "Column Rows",
        name: "columnrowid",
        type: "select",
        options: columnRowsOptions
    };

    const loadKPIColumnRows = async () => {
        setIsColumnsLoading(true);
        setSelectedColumnRows([]);
        setMultipleValues([]);
        setSubSectionKPIData([]);
        await Api.get(`SubSectionKPI/getColumnRows?kpiId=${selectedKPIID}`).then((result) => {
            if (result && result.data && result.data.response.length > 0) {
                const columnRows = result.data.response;
                const options = columnRows.map(item => ({ value: item.columnRowID, label: item.name }));
                setColumnRowsOptions(options);
                setColumnRows(columnRows);
                setShowColumnRowSelect(true);
                setIsColumnsLoading(false);
            } else {
                setShowColumnRowSelect(false);
                setIsColumnsLoading(false);
                setColumnRows([]);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    };








    const loadCompanies = async () => {
        Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.companyID, label: `${item.name}` }));
                setCompaniesOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadTobaccoType = async () => {
        await Api.get('TobaccoType/GetAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.tobaccoTypeID, text: item.name, hidden: false }));

                setTobaccoTypeOptions(options)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadCountries = async () => {
        await Api.get('Company/getAllCountries').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.countryID, text: item.name }));

                setCountriesOptions(options)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const filterColumnRows = () => {
        const filteredColumnRows = columnRows.filter((el) => {
            return multipleValues.some((f) => {
                return f.value === el.columnRowID;
            });
        });

        let subSectionKPIList = subSectionKPIData;
        let columnsKPIList = [];
        let columnsKPIListValues = [];

        setSubSectionKPIData([]);

        filteredColumnRows.forEach(e => {
            subSectionKPIList.push({
                kpiID: selectedKPIID,
                reportYearID: selectedReportYearID,
                columnRowID: e.columnRowID,
                fields: [...fields],
                name: e.name,
                internalName: e.name.replace(/\s/g, "")
            })
            e.SubSectionKPIConditionalfields = [

                {
                    label: "Value answer",
                    name: "value",
                    type: "input",
                    required: true
                },
                {
                    label: "Block when not answer",
                    name: "blockWhenNotAnswer",
                    type: "select",
                    options: booleanOptions,
                    required: true,
                },
                {
                    label: "Sub Section KPI ",
                    name: "subSectionKPIID",
                    type: "select",
                    required: true,
                    options: subSectionKPIsOptions,
                    multiple: true
                },

            ]
        });



        filteredColumnRows.map((col) => columnsKPIList.push({
            colID: col.columnRowID,
            multipleValues: [],
            selectCompanies: []
        }))

        subSectionKPIList.map((col) => {
            var postObj = {};
            postObj["columnRowID"] = col.columnRowID;
            postObj["kpiID"] = col.kpiID;
            postObj["reportYearID"] = col.reportYearID;
            postObj["subSectionKPIConditionalBlocks"] = []

            col.fields.map((f) => {
                return postObj[f.name] = "";
            })

            columnsKPIListValues.push(postObj);
        })

        setValuesObject(columnsKPIListValues);
        setSelectCustomValue(columnsKPIList);

        setSubSectionKPIData(subSectionKPIList);
        setSelectedColumnRows(filteredColumnRows);
    };

    const setSubSectionKPIValue = (column, field, colIndex, fieldIndex, value, form) => {
        const subSectionKpi = valuesObject.find(x => x.columnRowID == column.columnRowID);

        if (subSectionKpi != null && field.name == "unitMeasureID") {
            let unit = unitMeasureList.find(e => e.unitMeasureID == value)
            let typeInput = GetTypeInput(unit.unitMeasureID, unit.type)
            let columnRowsToChange = selectedColumnRows
            let changed = columnRowsToChange[colIndex]
            changed.SubSectionKPIConditionalfields[0].unitMeasure = typeInput
            columnRowsToChange[colIndex] = changed


            setSelectedColumnRows([...columnRowsToChange]);
        }

        if (subSectionKpi != null && field.type != "selectcustom" && field.type != "customOptions") {
            subSectionKpi[field.name] = value;
            if (field.onChanged != null) {
                field.onChanged(column, field);
            }
        } else if (field.type == "selectcustom") {
            subSectionKpi[field.name] = value.multipleValues.map((e) => { return e.value }).join(';');
        } else if (field.type == "customOptions") {
            var finalArray = [];
            var array = selectCustomValue[colIndex];

            subSectionKPIData.map((ssk) => {
                if (ssk.columnRowID == column.columnRowID) {
                    var multiValues = value
                    finalArray = ({ ...array, selectCompanies: multiValues })
                }
            })

            var filteredItens = selectCustomValue.filter(x => x.colID != column.columnRowID);
            var myColIndex = selectCustomValue.findIndex(e => e.colID == column.columnRowID);

            filteredItens.splice(myColIndex, 0, finalArray);

            setSelectCustomValue(filteredItens);

            if (finalArray.selectCompanies.length > 0) {
                subSectionKpi[field.name] = finalArray.selectCompanies.map((m) => {
                    var data = {
                        companyID: m.value
                    }
                    return data;
                });
            } else {
                subSectionKpi[field.name] = [];
            }

        }

        var myList = [];
        myList = valuesObject.filter(e => e.columnRowID != column.columnRowID);
        var myColIndex = valuesObject.findIndex(e => e.columnRowID == column.columnRowID);

        myList.splice(myColIndex, 0, subSectionKpi);
        setValuesObject(myList);
    };


    const setAnswersRules = (column, value, colIndex, field) => {
        let valueObjectToChange = valuesObject;
        let valuesToChange = valueObjectToChange.find(e => e.columnRowID == column.columnRowID)
        let indexToChange = valueObjectToChange.findIndex(e => e.columnRowID == column.columnRowID)
        if (valuesToChange) {
            valuesToChange.subSectionKPIConditionalBlocks[colIndex][field] = value
            valueObjectToChange[indexToChange] = valuesToChange;
            setValuesObject([...valueObjectToChange])
        }
    }

    const removeRule = (colIndex, column) => {
        let valueObjectToChange = valuesObject;
        let valuesToChange = valueObjectToChange.find(e => e.columnRowID == column.columnRowID)
        let indexToChange = valueObjectToChange.findIndex(e => e.columnRowID == column.columnRowID)
        if (valuesToChange) {
            valuesToChange.subSectionKPIConditionalBlocks.splice(colIndex, 1)
            valueObjectToChange[indexToChange] = valuesToChange;
            setValuesObject([...valueObjectToChange])
        }
    }



    useEffect(() => {
        loadCompanies()
        loadTobaccoType()
        loadCountries()
    }, []);

    useEffect(() => {
        if (subSectionKPIsOptions && subSectionKPIsOptions.length > 0) {
            let selectedColumnRowsToChange = selectedColumnRows;
            selectedColumnRowsToChange.forEach(element => {
                element.SubSectionKPIConditionalfields[1].options = subSectionKPIsOptions
            });

            setSelectedColumnRows([...selectedColumnRowsToChange])
            //TO DO
        }
    }, [subSectionKPIsOptions]);

    const booleanOptions = [
        { value: "", text: "Select an option" },
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const emptySelectOptions = [
        { value: 0, text: "Select an option" }
    ];

    const EvidenceCalculationMethodOptions = [
        { value: "SUM", text: "SUM" },
        { value: "COUNT", text: "COUNT" },
        { value: "AVG", text: "AVG" }
    ]

    const toleranceTypeOptions = [
        { value: "", text: "Select an option" },
        { value: 1, text: "Range Type" },
        { value: 2, text: "Greater Than" },
        { value: 3, text: "Lower Than" },
        { value: 4, text: "Equal To" },
        { value: 5, text: "Greater Than or Equal To" },
        { value: 6, text: "Lower Than or Equal To" },
        { value: 7, text: "Custom" },
        { value: 8, text: "Time" },
    ];

    const inputCalcOptions = [
        { value: "", text: "Select an option" },
        { value: "Calculation", text: "Calculation" },
        { value: "Input", text: "Input" },
        { value: "DisplayText", text: "DisplayText" }
    ];

    const evidenceAndDescriptionOptions = [
        { value: "", text: "Select an option" },
        { value: "Non-mandatory", text: "Non-mandatory" },
        { value: "Mandatory", text: "Mandatory" }
    ];

    const StandardTypeOptions = [
        { value: "", text: "Select an option" },
        { value: 1, text: "Variation" },
        { value: 2, text: "Between" },
        { value: 3, text: "Reference" },
        { value: 4, text: "SelectOption" }
    ];

    const reportYearSelect = {
        label: "Report Year",
        name: "reportYearID",
        type: "select",
        options: reportYearOptions
    };

    const kpiSelect = {
        label: "KPI",
        name: "kpiid",
        type: "select",
        options: KPIsOptions
    };

    const fields = [
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: booleanOptions,
            required: true,
        },
        {
            label: "Is Action Plan",
            name: "isActionPlan",
            type: "select",
            options: booleanOptions,
            required: true,
        },
        {
            label: "Mandatory",
            name: "mandatory",
            type: "select",
            options: booleanOptions,
            required: true,
        },
        {
            label: "Answer Type KPI",
            name: "unitMeasureID",
            type: "select",
            options: unitMeasureOptions,
            placeholder: { value: "", text: "" },
            required: true,
        },
        {
            label: "Ref Number",
            name: "refNumber",
            type: "number",
            required: true,
        },
        {
            label: "Options (custom)",
            name: "customOptions",
            type: "selectcustom",
            required: false,
        },
        {
            label: "Companies to exclude",
            name: "subSectionKPICompany",
            type: "customOptions",
            required: false,
            options: companiesOptions,
            multiple: true
        },
        {
            label: "Input/Calculation",
            name: "inputCalc",
            type: "select",
            options: inputCalcOptions,
            required: true,
        },
        {
            label: "Calculation",
            name: "calculation",
            type: "input", //Tem uma validação diferente por que os campos vão estar em linha
            required: false,
        },
        {
            /*
            * Options:
            *      Null - No tolerance
            *      1 - Range Type = Admin set number to Lower and Upper Tolerance
            *      2 - Greater Than = Admin set Validation to inputed number can't greater than inputed number in specific RefNumber
            *      3 - Lower Than = Admin set Validation to inputed number can't lower than inputed number in specific RefNumber
            *      4 - Equal To = Admin set Validation to inputed number need to be equals inputed number in specific RefNumber
            *      5 - Greater Than or Equal To = Admin set Validation to inputed number can't greater than or equal to inputed number in specific RefNumber
            *      6 - Lower Than or Equal To = Admin set Validation to inputed number can't lower than or equal to inputed number in specific RefNumber
            */
            label: "Tolerance Type",
            name: "toleranceType",
            type: "select",
            options: toleranceTypeOptions,
            required: false,
            onChanged: handleToleranceChange,
            placeholder: { value: "0", text: "No tolerance" }
        },
        {
            label: "Lower Tolerance",
            name: "lowerTolerance",
            type: "number",
            disabled: true,
            required: false
        },
        {
            label: "Upper Tolerance",
            name: "upperTolerance",
            type: "number",
            disabled: true,
            required: false
        },
        {
            label: "KPI Linked (Tolerance)",
            name: "toleranceKPILinkedRefNumber",
            type: "input",
            disabled: true,
            required: false
        },
        {
            label: "Alert Message (Tolerance)",
            name: "alertMessage",
            type: "textarea",
            disabled: true,
            required: false
        },
        {
            label: "Input Message",
            name: "inputMessage",
            type: "textarea",
            required: false
        },
        {
            label: "Evidence",
            name: "evidence",
            type: "select",
            options: evidenceAndDescriptionOptions,
            required: false
        },
        {
            label: "Comment",
            name: "comment",
            type: "select",
            options: evidenceAndDescriptionOptions,
            required: false
        },
        {
            label: "Standard Validation Type",
            name: "type",
            type: "select",
            options: StandardTypeOptions,
            required: false,
            onChanged: handleStandardType
        },
        {
            label: "Reference Value",
            name: "referenceValue",
            type: "input",
            disabled: true,
            required: false
        },
        {
            label: "Lower Value",
            name: "lowerValue",
            type: "input",
            disabled: true,
            required: false
        },
        {
            label: "Upper Value",
            name: "upperValue",
            type: "input",
            disabled: true,
            required: false
        },
        {
            label: "Variation",
            name: "variation",
            type: "input",
            disabled: true,
            required: false
        },
    ];


    useEffect(() => {
        if (selectedKPIID != 0) {
            setIsColumnsLoading(true);
            loadKPIColumnRows();
            setIsColumnsLoading(false);
        }
    }, [selectedKPIID]);

    useEffect(() => {
        if (selectedReportYearID != 0) {
            setIsColumnsLoading(true);
            loadSubSectionKPIs();
            loadKPIs();
            setIsColumnsLoading(false);
        }
    }, [selectedReportYearID]);

    const loadData = async () => {
        try {
            const result = await Api.get('ReportYear/getAll')
            if (result && result.data) {
                var options = result.data.response.map(item => ({ value: item.reportYearID, text: `${item.year}` }));
                options = [{ value: "", text: "Select a Report Year" }, ...options];
                setReportYearOptions(options);
                setSelectedReportYearID(options.length > 0 ? options[0].value : 0);
            }
        } catch (error) {
            exceptionNotificationAPI(error);
        };

        Api.get('UnitMeasure/getAll').then((result) => {
            if (result && result.data) {
                setUnitMeasureList(result.data.response)
                let options = result.data.response.map(item => ({ value: item.unitMeasureID, text: item.description + " - " + item.type }));

                options = [{ value: "", text: "Select a Unit Measure" }, ...options];

                setUnitMeasureOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadKPIs = async () => {
        Api.get(`KPI/getAllByReportYearID?reportYearID=${selectedReportYearID}`).then((result) => {
            if (result && result.data) {
                let options = result.data.response.map(item => ({ value: item.kpiid, text: (item.subSectionDescription ? item.subSectionDescription : item.kpiid) + " - " + item.description }));

                options = [{ value: "", text: "Select a KPI" }, ...options];

                setKPIsOptions(options);
                setSelectedKPIID(options.length > 0 ? options[0].value : 0);
                setShowKPISelect(true);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadSubSectionKPIs = async () => {
        Api.get(`SubSectionKPI/getAll?reportYearID=${selectedReportYearID}`).then((result) => {
            if (result && result.data) {
                let options = result.data.response.map(item => ({ value: item.subSectionKPIID, label: item.refNumber }));

                options = [{ value: "", text: "Select a SubSection KPI" }, ...options];

                setSubSectionKPIsOptions(options);
                //setSelectedKPIID(options.length > 0 ? options[0].value : 0);
                //setShowKPISelect(true);
                // console.log(kpiSelect);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }


    const handleSubmit = async (data, e) => {
        try {
            var obj = []
            var postObj = [];
            const submitUrl = `SubSectionKPI/new`;

            valuesObject.map((ssk, i) => {
                fields.map(f => {
                    if (f.required !== undefined && f.required === false) {
                        return null;
                    } else if (f.required == true && (ssk[f.name] == null || ssk[f.name] == undefined || ssk[f.name] == "")) {
                        obj[f.name] = Yup.string().required(`${f.label} is required`);
                        var selector = "#form-input-" + ssk.columnRowID + "-" + f.name;
                        var input = document.querySelector(selector);
                        if (input != null) {
                            input.style.borderColor = "#CC0000";
                        }
                    }
                    else {
                        var selector = "#form-input-" + ssk.columnRowID + "-" + f.name;
                        var input = document.querySelector(selector);
                        if (input != null) {
                            input.style.borderColor = "";
                        }
                    }
                })
            });


            const schema = Yup.object().shape(obj);

            await schema.validate(data, {
                abortEarly: false,
            });

            await Api.post(submitUrl, valuesObject).then((result) => {
                if (result && result.data) {
                    success_message("Saved successfuly");
                    history.goBack();
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);

                formRef.current.setErrors(errors);

                error_message("Have some mandatory inputs that are empty, please fill with some information!");
            }
        }
    }

    useEffect(() => {
        setIsLoading(true);
        loadData();
        setIsLoading(false);
    }, []);

    return (
        <Container className="container">
            {!isLoading ?
                <div>
                    <h3>{(id != null) ? "Edit" : "New"} Sub Section KPI</h3>
                    <Form onSubmit={handleSubmit} ref={formRef}>
                        <table className="table table-striped">
                            <tbody>
                                <tr key={"ReportYear-Tr"} id={reportYearSelect.name + "Tr"}>
                                    <td width="25%">
                                        <label>
                                            {`${reportYearSelect.label}`}  <span className="required">*</span>
                                        </label>
                                    </td>
                                    <td width="75%">
                                        <FormInput
                                            className="form-control"
                                            name={reportYearSelect.name}
                                            id={reportYearSelect.name}
                                            disabled={reportYearSelect.disabled ? reportYearSelect.disabled : false}
                                            checked={reportYearSelect.checked}
                                            value={reportYearSelect.value}
                                            inputType={reportYearSelect.type}
                                            options={reportYearOptions}
                                            onChange={(e) => setSelectedReportYearID(e.target.value)} />
                                    </td>
                                </tr>
                                {showKPISelect ?
                                    <tr key={"KPI-Tr"} id={kpiSelect.name + "Tr"}>
                                        <td width="25%">
                                            <label>
                                                {`${kpiSelect.label}`}  <span className="required">*</span>
                                            </label>
                                        </td>
                                        <td width="75%">
                                            <FormInput
                                                className="form-control"
                                                name={kpiSelect.name}
                                                id={kpiSelect.name}
                                                disabled={kpiSelect.disabled ? kpiSelect.disabled : false}
                                                checked={kpiSelect.checked}
                                                value={kpiSelect.value}
                                                inputType={kpiSelect.type}
                                                options={KPIsOptions}
                                                onChange={(e) => setSelectedKPIID(e.target.value)} />
                                        </td>
                                    </tr>
                                    : <></>
                                }
                                {
                                    showColumnRowSelect ?
                                        <tr key={"ColumnRow-Tr"} id={columnRowSelect.name + "Tr"}>
                                            <td width="25%">
                                                <label>
                                                    {`${columnRowSelect.label}`}  <span className="required">*</span>
                                                </label>
                                            </td>
                                            <td width="75%">
                                                <Select
                                                    options={columnRowsOptions}
                                                    value={multipleValues}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    onChange={(a) => setMultipleValues(a)}
                                                />
                                            </td>
                                        </tr> : isColumnsLoading ?
                                            <tr><td colSpan="2"><TableLoading></TableLoading></td></tr> : <></>
                                }
                            </tbody>
                        </table>
                        {
                            showColumnRowSelect ?
                                <div className="d-flex flex-row mt-2" style={{ justifyContent: 'end' }}>
                                    <Button onClick={() => { filterColumnRows(multipleValues) }}>{selectedColumnRows.length > 0 ? "Update" : "Add"}</Button>
                                </div> : <></>
                        }
                        {
                            selectedColumnRows.length > 0 ?
                                <>
                                    <Accordion className='subsection-kpi-accordion'>
                                        {selectedColumnRows.length > 0 ?
                                            selectedColumnRows.map((column, colIndex) => {
                                                // insert accordion here
                                                return <div className="accordion-item" key={`${colIndex}`}>
                                                    <h2 className="accordion-header" id={`heading-${colIndex}`}>
                                                        <button className={selectedAccordionItems.includes(colIndex) ? 'collapsed' : ''} onClick={() => filterColumnRow(colIndex)} id={`${colIndex}`} type="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls={`collapse-${colIndex}`}>
                                                            <GoChevronDown size={20} />
                                                            <span className="column-name">{column.name}</span>
                                                        </button>
                                                    </h2>
                                                    <div className={`accordion-collapse collapse ${selectedAccordionItems.includes(colIndex) ? 'show' : ''} ${column.internalName}`} aria-labelledby={`heading-${colIndex}`} data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <table className="table table-striped">
                                                                <tbody>
                                                                    {
                                                                        fields.map((field, fieldIndex) => {
                                                                            if (id !== undefined && field.editable !== undefined && field.editable === false) {
                                                                                return <></>
                                                                            }
                                                                            else {
                                                                                return (<>
                                                                                    <tr key={`Tr-${column.columnRowID}-${field.name}`} id={`Tr-${column.columnRowID}-${field.name}`}>
                                                                                        <td width="25%">
                                                                                            <label>
                                                                                                {`${field.label}`}  {field.required === undefined || field.required === true ? <span className="required">*</span> : <></>}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td width="75%">
                                                                                            {field.type == "customOptions" ?
                                                                                                <Select
                                                                                                    name={`select-${column.columnRowID}-${field.name}`}
                                                                                                    id={`select-${column.columnRowID}-${field.name}`}
                                                                                                    className={`form-control ${field.name}`}
                                                                                                    options={field.options}
                                                                                                    value={selectCustomValue[colIndex].selectCompanies}
                                                                                                    isMulti
                                                                                                    closeMenuOnSelect={false}
                                                                                                    onChange={(a) => setSubSectionKPIValue(column, field, colIndex, fieldIndex, a)}
                                                                                                />
                                                                                                : field.type == "selectcustom" ?
                                                                                                    <CreatableSelect
                                                                                                        name="customOptions"
                                                                                                        className={`form-control ${field.name}`}
                                                                                                        id="customOptions"
                                                                                                        components={{ DropdownIndicator: null }}
                                                                                                        isClearable={true}
                                                                                                        isMulti
                                                                                                        menuIsOpen={false}
                                                                                                        placeholder="Type something and press enter..."
                                                                                                        onChange={(e) => {
                                                                                                            var finalArray = [];
                                                                                                            var array = selectCustomValue[colIndex];
                                                                                                            subSectionKPIData.map((ssk) => {
                                                                                                                if (ssk.columnRowID == column.columnRowID) {
                                                                                                                    var multiValues = e;
                                                                                                                    finalArray = ({ ...array, multipleValues: multiValues })
                                                                                                                }
                                                                                                            })

                                                                                                            var filteredItens = selectCustomValue.filter(x => x.colID != column.columnRowID);
                                                                                                            var myColIndex = selectCustomValue.findIndex(e => e.colID == column.columnRowID);

                                                                                                            filteredItens.splice(myColIndex, 0, finalArray);

                                                                                                            setSelectCustomInput("");
                                                                                                            setSelectCustomValue(filteredItens);
                                                                                                            setSubSectionKPIValue(column, field, colIndex, fieldIndex, finalArray)
                                                                                                        }}
                                                                                                        onInputChange={(e) => setSelectCustomInput(e)}
                                                                                                        onKeyDown={(e) => {
                                                                                                            switch (e.key) {
                                                                                                                case 'Enter':
                                                                                                                case 'Tab':
                                                                                                                    if (selectCustomInput == "")
                                                                                                                        return;
                                                                                                                    var newData = {
                                                                                                                        "value": selectCustomInput,
                                                                                                                        "label": selectCustomInput
                                                                                                                    }
                                                                                                                    var finalArray = [];
                                                                                                                    var array = selectCustomValue[colIndex];
                                                                                                                    subSectionKPIData.map((ssk) => {
                                                                                                                        if (ssk.columnRowID == column.columnRowID) {
                                                                                                                            var multiValues = [...array.multipleValues, newData]
                                                                                                                            finalArray = ({ ...array, multipleValues: multiValues })
                                                                                                                        }
                                                                                                                    })

                                                                                                                    var filteredItens = selectCustomValue.filter(x => x.colID != column.columnRowID);
                                                                                                                    var myColIndex = selectCustomValue.findIndex(e => e.colID == column.columnRowID);

                                                                                                                    filteredItens.splice(myColIndex, 0, finalArray);

                                                                                                                    setSelectCustomInput("");
                                                                                                                    setSelectCustomValue(filteredItens);
                                                                                                                    setSubSectionKPIValue(column, field, colIndex, fieldIndex, finalArray)
                                                                                                                    e.preventDefault();
                                                                                                            }
                                                                                                        }}
                                                                                                        value={selectCustomValue[colIndex].multipleValues}
                                                                                                        inputValue={selectCustomInput}
                                                                                                    />
                                                                                                    :
                                                                                                    <FormInput
                                                                                                        className={`form-control ${field.name}`}
                                                                                                        name={`form-input-${column.columnRowID}-${field.name}`}
                                                                                                        id={`form-input-${column.columnRowID}-${field.name}`}
                                                                                                        disabled={field.disabled ? field.disabled : false}
                                                                                                        checked={field.checked}
                                                                                                        inputType={field.type}
                                                                                                        options={field.options}
                                                                                                        onChange={(e) => setSubSectionKPIValue(column, field, colIndex, fieldIndex, field.type == "check" ? e.target.checked : e.target.value, formRef.current)} />
                                                                                            }
                                                                                        </td>
                                                                                    </tr>

                                                                                </>)
                                                                            }
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className='mt-5'>
                                                            {

                                                                <a className="addnewLine btn-primary" style={{ padding: '0.5rem 1rem', textDecoration: 'none', cursor: 'pointer' }} onClick={() => {
                                                                    let objDisable = valuesObject.filter(e => e.columnRowID == column.columnRowID)[0]
                                                                    let indexDisable = valuesObject.findIndex(e => e.columnRowID == column.columnRowID)
                                                                    if (objDisable && objDisable.unitMeasureID && objDisable.unitMeasureID != "" && subSectionKPIsOptions) {
                                                                        let changedObjectValues = valuesObject
                                                                        objDisable.subSectionKPIConditionalBlocks.push({
                                                                            value: "",
                                                                            subSectionKPIIDs: ""
                                                                        })

                                                                        changedObjectValues[indexDisable] = objDisable
                                                                        setValuesObject([...changedObjectValues])

                                                                    }
                                                                }}>Add New Conditional Block</a>

                                                            }
                                                        </div>
                                                        {
                                                            column.SubSectionKPIConditionalfields && column.SubSectionKPIConditionalfields.length > 0 && !isColumnsLoading ?
                                                                <table className="table table-striped my-5">
                                                                    <thead>
                                                                        <tr><th scope="col">{"Index"}</th>
                                                                            {column.SubSectionKPIConditionalfields.map((cs) => {
                                                                                return (
                                                                                    <th scope="col text-center">{cs.label}</th>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            valuesObject.filter(e => e.columnRowID == column.columnRowID).map((values) => {
                                                                                return (
                                                                                    values.subSectionKPIConditionalBlocks.map((col, colinx) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <label>
                                                                                                        {colinx + 1}
                                                                                                    </label>
                                                                                                </td>
                                                                                                {column.SubSectionKPIConditionalfields.map((c, ind) => {
                                                                                                    return (
                                                                                                        ind == 0 ? <td scope="row">

                                                                                                            {
                                                                                                                c.unitMeasure && c.unitMeasure.inputType == 'selectfuel' ?

                                                                                                                    <FormInput
                                                                                                                        //options={fuelTypeOptions}
                                                                                                                        name={c.name}
                                                                                                                        id={c.name + ind}
                                                                                                                        inputType={"input"}
                                                                                                                        autoComplete="off"
                                                                                                                        value={col.value != null ? col.value : ""}
                                                                                                                        onChange={(f) => {
                                                                                                                            setAnswersRules(column, f.target.value, colinx, "value")
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    :

                                                                                                                    (c.unitMeasure && c.unitMeasure.inputType == 'selectcustom' ?
                                                                                                                        <FormInput
                                                                                                                            customOptions={values['customOptions']}
                                                                                                                            name={c.name}
                                                                                                                            id={c.name + ind}
                                                                                                                            inputType={c.unitMeasure.inputType}
                                                                                                                            autoComplete="off"
                                                                                                                            value={col.value != null ? col.value : ""}
                                                                                                                            onChange={(f) => {
                                                                                                                                setAnswersRules(column, f.target.value, colinx, "value")
                                                                                                                            }}
                                                                                                                        /> :
                                                                                                                        <FormInput
                                                                                                                            name={c.name}
                                                                                                                            id={c.name + ind}
                                                                                                                            autoComplete="off"
                                                                                                                            value={col.value != null ? col.value : ""}
                                                                                                                            //title={moduleObject.isEditable && value != null ? subSectionKPITarget.kpi.inputMessage : ""}
                                                                                                                            inputType={c.unitMeasure && c.unitMeasure.inputType != null && c.unitMeasure.inputType != "" ? c.unitMeasure.inputType : "text"}
                                                                                                                            //className={moduleObject.isEditable ? "" : "read-only"}
                                                                                                                            //customOptions={subSectionKPITarget.kpi.customOptions}
                                                                                                                            //placeholder={c.unitMeasure.inputType == "selectcustom" ? { value: "", text: "Select an option" } : null}
                                                                                                                            //disabled={moduleObject.isEditable && country != null ? false : true}
                                                                                                                            onChange={(f) => {
                                                                                                                                setAnswersRules(column, f.target.value, colinx, "value")
                                                                                                                            }
                                                                                                                            }

                                                                                                                        />
                                                                                                                    )
                                                                                                            }
                                                                                                        </td>
                                                                                                            :
                                                                                                            ind == 1 ?
                                                                                                                <td style={{ width: "20rem" }}>
                                                                                                                    <FormInput
                                                                                                                        name={c.name}
                                                                                                                        id={c.name + ind}
                                                                                                                        disabled={c.disabled ? c.disabled : false}
                                                                                                                        value={col.blockWhenNotAnswer != null ? col.blockWhenNotAnswer : ""}
                                                                                                                        inputType={c.type}
                                                                                                                        options={c.options}
                                                                                                                        onChange={(f) => {
                                                                                                                            setAnswersRules(column, f.target.value, colinx, "blockWhenNotAnswer")
                                                                                                                        }
                                                                                                                        }
                                                                                                                    />
                                                                                                                </td>
                                                                                                                :
                                                                                                                <td style={{ width: "40rem" }}>
                                                                                                                    <Select
                                                                                                                        options={c.options}
                                                                                                                        value={col.subSectionKPIIDs}
                                                                                                                        isMulti
                                                                                                                        closeMenuOnSelect={false}
                                                                                                                        onChange={(a) => setAnswersRules(column, a, colinx, "subSectionKPIIDs")}
                                                                                                                    />



                                                                                                                </td>
                                                                                                    )
                                                                                                })}
                                                                                                {colinx != 0 ? <td><AiOutlineDelete size={20} onClick={(e) => { removeRule(colinx, column) }/*handleRemoveColumnRowInputs(e.target.value, colinx)*/} /></td> : <></>}
                                                                                            </tr>
                                                                                        )
                                                                                    })

                                                                                )
                                                                            }
                                                                            )}
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                            }) : <></>}

                                    </Accordion>
                                    <div className="d-flex flex-row mt-2">
                                        <CancelButton />
                                        <Button type="submit">Save</Button>
                                    </div>
                                </> : <></>
                        }
                    </Form>
                </div>
                : <TableLoading></TableLoading>}
        </Container>
    );
}

export default SubSectionKPIForm;
