import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import Api, { exceptionNotificationAPI } from '../../services/api';
import Form from 'react-bootstrap/Form';
import { handleAddZeroes } from '../../services/functions';
import ModalRejectPartial from '../ModalRejectPartial';
//Components
import { info_message, success_message } from '../Toast/index';

//Style
import { Container } from './styles';
import { FormCheck } from 'react-bootstrap';

const ModuleButtons = ({ userRoles, showButtons, isEditable, moduleID, answers,
    selectedCompanyID, selectedReportYearID, loadData, save, moduleObject, setModuleObject,
    setLoading, setLoadingSection = null, waiverIdentifier = null, status = null, setShowFixedHeader = null, setReadConsent = null, readConsent = null, versionID = null, needReadConsent = null, subSectionKPIs, selectedTobaccoTypes, selectedCountries, selectedFuelTypes, fixedKpisList }) => {

    const [showAgreeConsent, setShowAgreeConsent] = useState(false);
    const [showModalRejectPartial, setShowModalRejectPartial] = useState(false);
    const [subSectionKPIsToReject, setSubSectionKPIsToReject] = useState([]);
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:user')));
    const [showSendButton, setShowSendButton] = useState(false);

    const [ssksToReject, setSsksToReject] = useState([]);
    const COLUMN_ROW_TYPES = {
        'Simple': 1,
        'Country': 2,
        'FuelType': 3,
        'TobaccoType': 4,
        'Year': 5,
    }

    useEffect(() => {
        if(waiverIdentifier != null) {
            if(status != 3 || status != 5) {
                setShowSendButton(true)  
            }
        }
        else if (waiverIdentifier == null && status != 7) {
            setShowSendButton(true)
        }
    },[])

    const handleSend = async (event) => {
        event.preventDefault();

        var isFinalVersion = moduleObject?.version?.isFinalVersion;
        var isPartialRejected = moduleObject?.version?.rejectedFormValuesList != null && moduleObject?.version?.rejectedFormValuesList.length > 0;

        if (isPartialRejected) {
            Swal.fire({
                title: 'Notice!',
                text: "This module has already been previously submitted and the global approver rejected only a few KPIs. This means you must submit the new responses keeping the same version as previous submission. Click in the button below to proceed with the approval process.",
                icon: 'warning',
                confirmButtonColor: '#B0B0BB',
                confirmButtonText: 'Send',
                showCloseButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await save(answers, moduleID, true, true, false);
                }
            })

        } else {
            save(answers, moduleID, true, true, false);

        }
    }

    const handleSaveActionPlans = async (event) => {
        event.preventDefault();
        save(answers, moduleID, true, true, true);        
    }

    const handleApprove = async (event) => {
        event.preventDefault();

        setLoading(true);
        if (setLoadingSection != null) {
            setLoadingSection(true);
        }

        await Api.post(`FormValue/approve?moduleID=${moduleID}&selectedCompanyID=${selectedCompanyID}&reportYearID=${selectedReportYearID}${waiverIdentifier != null ? `&waiverIdentifier=${waiverIdentifier}` : ""}`).then((result) => {
            success_message("The data was approved successfully!");
            loadData();
            if (setShowFixedHeader != null)
                setShowFixedHeader(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setLoading(false);
            if (setLoadingSection != null) {
                setLoadingSection(false);
            }
            if (setShowFixedHeader != null)
                setShowFixedHeader(false);
        });
    }

    function validateSelectAnswers(colType, indexVal) {
        if (colType.toString() == COLUMN_ROW_TYPES['Country'].toString()) {
            return selectedCountries.find(c => c.value.toString() == indexVal.toString())?.text;
        } else if (colType.toString() == COLUMN_ROW_TYPES['FuelType'].toString()) {
            return selectedFuelTypes.find(c => c.value.toString() == indexVal.toString())?.text;
        } else if (colType.toString() == COLUMN_ROW_TYPES['TobaccoType'].toString()) {
            return selectedTobaccoTypes.find(c => c.value.toString() == indexVal.toString())?.text;
        }
    }

    function validateSelectedIndex(colType, index) {
        if (colType.toString() == COLUMN_ROW_TYPES['Country'].toString()) {
            return selectedCountries[index]?.text;
        } else if (colType.toString() == COLUMN_ROW_TYPES['FuelType'].toString()) {
            return selectedFuelTypes[index]?.text;
        } else if (colType.toString() == COLUMN_ROW_TYPES['TobaccoType'].toString()) {
            return selectedTobaccoTypes[index]?.text;
        }
    }

    const handlePartialReject = async (event) => {
        event.preventDefault();

        //Kpis
        var kpis = [];
        moduleObject.data.sections.map((section) => {
            section.subSections.map((ss) => {
                ss.kpis.map((kpi) => {
                    kpis.push(kpi);
                });
            });
        });

        //Sections
        var sections = [];
        moduleObject.data.sections.map((section) => {
            sections.push(section);
        });

        //Answers
        var answers = moduleObject.answers;
        //Columns
        var cols = moduleObject.columnRows;
        //ParentColumns
        var parentCols = cols.filter(c => c.isParentColumn);

        //subSections
        var subSections = []
        moduleObject.data.sections.map((section) => {
            section.subSections.map((ss) => {
                subSections.push(ss);
            });
        });

        setShowModalRejectPartial(true);
        setSsksToReject([]);

        var sskRejectList = [];
        var bold = false; //Controla bold
        var isHighlight = false; // Controla Highlight
        var lastRowSSkpi = null; //Controla primeiro objeto de rows
        var userObj = JSON.parse(localStorage.getItem('@QualityApp:user'));
        var isApproving = false;
        //Validate if user is global
        if ((moduleObject?.version?.status == 4 && userObj?.companyID == null && userRoles.includes("RoleApprover"))) {
            isApproving = true;
        }
        subSectionKPIs.forEach((ssk) => {
            bold = false;
            isHighlight = false;

            if (ssk.inputCalc != "Calculation") {
                let fromSubSection = subSections.find(ss => ss.subSectionID == ssk.subSectionID);
                let fromSection = sections.find(s => s.sectionID == fromSubSection?.sectionID);
                //If subsection has a number of Rows
                if (fromSubSection.numberOfRows != null && fromSubSection.numberOfRows > 0) {
                    //Add first entry with row values
                    if (lastRowSSkpi == null) {
                        lastRowSSkpi = ssk.refNumber;
                    }

                    var targetKpi = kpis.find(x => x.kpiid == ssk.kpiid);
                    var targetColumn = cols.find(c => c.columnRowID == ssk.columnRowID);
                    var targetParentColumn = targetColumn.parentColumnRowID != null ? parentCols.find(pc => pc.columnRowOrder == targetColumn.parentColumnRowID && pc.subSectionID == targetColumn.subSectionID) : null
                    var targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID && a.row == 0);
                    var answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;

                    var columnDescription = targetColumn.name;

                    if (targetColumn.type != 1 && targetColumn.type != 5 && targetAnswer != null) {
                        //Validate if answer needs to be altered by selector description - isSelectAnswer
                        if (targetColumn.kpiReference == targetAnswer.refNumber) {
                            answer = validateSelectAnswers(targetColumn.type, answer);
                        }
                    }

                    if (targetParentColumn != null) {
                        columnDescription = `${targetParentColumn.name} - ${targetColumn.name}`
                    }

                    if (lastRowSSkpi == ssk.refNumber) {
                        bold = true;
                    }



                    //Validate if user is global
                    if ((moduleObject?.version?.status == 4 && userObj?.companyID == null && userRoles.includes("RoleApprover"))) {
                        isApproving = true;
                    }

                    if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID && x.rowIndex == 0) && !moduleObject.isEditable && isApproving) {
                        isHighlight = true;
                    }


                    if (blockLocalApproverRejection()) {
                        for (let ind = 0; ind < moduleObject.version?.globalRejectFormValuesList?.length; ind++) {
                            let element = moduleObject.version?.globalRejectFormValuesList[ind];
                            if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == 0 && element.columnIndex == null) {
                                sskRejectList.push(
                                    {
                                        rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                        subSectionKpiID: ssk.subSectionKPIID,
                                        refNumber: ssk.refNumber,
                                        rowIndex: 0,
                                        columnIndex: null,
                                        kpiDescription: targetKpi?.description,
                                        columnDesc: columnDescription,
                                        answer: answer,
                                        unitMeasure: ssk.unitMeasure,
                                        isBold: bold,
                                        subSectionID: ssk.subSectionID,
                                        sectionID: fromSection?.sectionID,
                                        isHighlight: isHighlight
                                    }
                                );
                            }
                        }
                    } else {
                        sskRejectList.push(
                            {
                                rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                subSectionKpiID: ssk.subSectionKPIID,
                                refNumber: ssk.refNumber,
                                rowIndex: 0,
                                columnIndex: null,
                                kpiDescription: targetKpi?.description,
                                columnDesc: columnDescription,
                                answer: answer,
                                unitMeasure: ssk.unitMeasure,
                                isBold: bold,
                                subSectionID: ssk.subSectionID,
                                sectionID: fromSection?.sectionID,
                                isHighlight: isHighlight
                            }
                        );
                    }



                    //add extra SSK for each of the other rows
                    for (let i = 1; i < fromSubSection.numberOfRows; i++) {
                        isHighlight = false;
                        targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID && a.row == i);
                        answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;
                        if (targetColumn.type != 1 && targetColumn.type != 5 && answer != null) {
                            //Validate if answer needs to be altered by selector description - isSelectAnswer
                            if (targetColumn.kpiReference == targetAnswer.refNumber) {
                                answer = validateSelectAnswers(targetColumn.type, answer);
                            }
                        }

                        if (lastRowSSkpi == ssk.refNumber) {
                            bold = true;
                        }

                        if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID && x.rowIndex == i) && !moduleObject.isEditable && isApproving) {
                            isHighlight = true;
                        }

                        if (blockLocalApproverRejection()) {
                            for (let ind = 0; ind < moduleObject.version?.globalRejectFormValuesList?.length; ind++) {
                                let element = moduleObject.version?.globalRejectFormValuesList[ind];
                                if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == i && element.columnIndex == null) {
                                    sskRejectList.push(
                                        {
                                            rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                            subSectionKpiID: ssk.subSectionKPIID,
                                            refNumber: ssk.refNumber,
                                            rowIndex: i,
                                            columnIndex: null,
                                            kpiDescription: targetKpi?.description,
                                            columnDesc: columnDescription,
                                            answer: answer,
                                            unitMeasure: ssk.unitMeasure,
                                            isBold: bold,
                                            subSectionID: ssk.subSectionID,
                                            sectionID: fromSection?.sectionID,
                                            isHighlight: isHighlight
                                        });
                                }
                            };
                        } else {
                            sskRejectList.push(
                                {
                                    rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                    subSectionKpiID: ssk.subSectionKPIID,
                                    refNumber: ssk.refNumber,
                                    rowIndex: i,
                                    columnIndex: null,
                                    kpiDescription: targetKpi?.description,
                                    columnDesc: columnDescription,
                                    answer: answer,
                                    unitMeasure: ssk.unitMeasure,
                                    isBold: bold,
                                    subSectionID: ssk.subSectionID,
                                    sectionID: fromSection?.sectionID,
                                    isHighlight: isHighlight
                                });
                        }
                    }

                }
                else {
                    lastRowSSkpi = null;
                    bold = false;
                    var targetKpi = kpis.find(x => x.kpiid == ssk.kpiid);
                    var targetColumn = cols.find(c => c.columnRowID == ssk.columnRowID);

                    //validate if targetColumn is tobacco type
                    if (targetColumn.type.toString() == COLUMN_ROW_TYPES['TobaccoType'].toString()) {

                        var targetParentColumn = targetColumn.parentColumnRowID != null ? parentCols.find(pc => pc.columnRowOrder == targetColumn.parentColumnRowID && pc.subSectionID == targetColumn.subSectionID) : null
                        var targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID && a.column == 0);
                        var answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;

                        var columnDescription = answer;



                        if (targetColumn.type != 1 && targetColumn.type != 5) {
                            //Adjust column description to match value selected
                            columnDescription = validateSelectedIndex(targetColumn.type, 0);

                            if (answer != null) {
                                //Validate if answer needs to be altered by selector description - isSelectAnswer
                                if (targetColumn?.kpiReference == targetAnswer?.refNumber) {
                                    answer = validateSelectAnswers(targetColumn.type, answer);
                                }
                            }
                        }


                        if (targetParentColumn != null) {
                            columnDescription = `${targetParentColumn.name} - ${columnDescription}`
                        }

                        if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID && x.columnIndex == 0) && !moduleObject.isEditable && isApproving) {
                            isHighlight = true;
                        }

                        if (columnDescription != "" && columnDescription != null) {
                            if (blockLocalApproverRejection()) {
                                for (let ind = 0; ind < moduleObject.version?.globalRejectFormValuesList?.length; ind++) {
                                    let element = moduleObject.version?.globalRejectFormValuesList[ind];
                                    if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == null && element.columnIndex == 0) {
                                        sskRejectList.push(
                                            {
                                                rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                                subSectionKpiID: ssk.subSectionKPIID,
                                                refNumber: ssk.refNumber,
                                                rowIndex: null,
                                                columnIndex: 0,
                                                kpiDescription: targetKpi?.description,
                                                columnDesc: columnDescription,
                                                answer: answer,
                                                unitMeasure: ssk.unitMeasure,
                                                isBold: bold,
                                                subSectionID: ssk.subSectionID,
                                                sectionID: fromSection?.sectionID,
                                                isHighlight: isHighlight
                                            }
                                        );
                                    }
                                }
                            } else {
                                sskRejectList.push(
                                    {
                                        rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                        subSectionKpiID: ssk.subSectionKPIID,
                                        refNumber: ssk.refNumber,
                                        rowIndex: null,
                                        columnIndex: 0,
                                        kpiDescription: targetKpi?.description,
                                        columnDesc: columnDescription,
                                        answer: answer,
                                        unitMeasure: ssk.unitMeasure,
                                        isBold: bold,
                                        subSectionID: ssk.subSectionID,
                                        sectionID: fromSection?.sectionID,
                                        isHighlight: isHighlight
                                    }
                                );
                            }
                        }


                        //add extra SSK for each of the other columns
                        for (let i = 1; i < targetColumn.minimumQuantity; i++) {

                            targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID && a.column == i);
                            answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;
                            columnDescription = targetColumn.name;
                            isHighlight = false;
                            //Validate if columnsType is from a selectedValue (TobaccoType for example)
                            if (targetColumn.type != 1 && targetColumn.type != 5) {
                                //Adjust column description to match value selected
                                columnDescription = validateSelectedIndex(targetColumn.type, i);
                                if (answer != null) {
                                    //Validate if answer needs to be altered by selector description - isSelectAnswer
                                    if (targetColumn?.kpiReference == targetAnswer?.refNumber) {
                                        answer = validateSelectAnswers(targetColumn.type, answer);
                                        columnDescription = answer;
                                    }
                                }
                            }

                            if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID && x.columnIndex == i) && !moduleObject.isEditable && isApproving) {
                                isHighlight = true;
                            }

                            if (columnDescription != "" && columnDescription != null) {
                                if (blockLocalApproverRejection()) {
                                    for (let ind = 0; ind < moduleObject.version?.globalRejectFormValuesList?.length; ind++) {
                                        let element = moduleObject.version?.globalRejectFormValuesList[ind];
                                        if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == null && element.columnIndex == i) {
                                            sskRejectList.push(
                                                {
                                                    rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                                    subSectionKpiID: ssk.subSectionKPIID,
                                                    refNumber: ssk.refNumber,
                                                    rowIndex: null,
                                                    columnIndex: i,
                                                    kpiDescription: targetKpi?.description,
                                                    columnDesc: columnDescription,
                                                    answer: answer,
                                                    unitMeasure: ssk.unitMeasure,
                                                    isBold: bold,
                                                    subSectionID: ssk.subSectionID,
                                                    sectionID: fromSection?.sectionID,
                                                    isHighlight: isHighlight
                                                }
                                            );
                                        }
                                    }
                                } else {
                                    sskRejectList.push(
                                        {
                                            rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                            subSectionKpiID: ssk.subSectionKPIID,
                                            refNumber: ssk.refNumber,
                                            rowIndex: null,
                                            columnIndex: i,
                                            kpiDescription: targetKpi?.description,
                                            columnDesc: columnDescription,
                                            answer: answer,
                                            unitMeasure: ssk.unitMeasure,
                                            isBold: bold,
                                            subSectionID: ssk.subSectionID,
                                            sectionID: fromSection?.sectionID,
                                            isHighlight: isHighlight
                                        }
                                    );
                                }
                            }
                        }
                    } else {

                        var targetParentColumn = targetColumn.parentColumnRowID != null ? parentCols.find(pc => pc.columnRowOrder == targetColumn.parentColumnRowID && pc.subSectionID == targetColumn.subSectionID) : null
                        var targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID);
                        var answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;
                        var columnDescription = targetColumn.name;

                        if (targetParentColumn != null) {
                            columnDescription = `${targetParentColumn.name} - ${targetColumn.name}`
                        }

                        if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID) && !moduleObject.isEditable && isApproving) {
                            isHighlight = true;
                        }

                        if (blockLocalApproverRejection()) {
                            for (let ind = 0; ind < moduleObject.version?.globalRejectFormValuesList?.length; ind++) {
                                let element = moduleObject.version?.globalRejectFormValuesList[ind];
                                if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == null && element.columnIndex == null) {
                                    sskRejectList.push(
                                        {
                                            rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                            subSectionKpiID: ssk.subSectionKPIID,
                                            refNumber: ssk.refNumber,
                                            rowIndex: null,
                                            columnIndex: null,
                                            kpiDescription: targetKpi?.description,
                                            columnDesc: columnDescription,
                                            answer: answer,
                                            unitMeasure: ssk.unitMeasure,
                                            isBold: bold,
                                            subSectionID: ssk.subSectionID,
                                            sectionID: fromSection?.sectionID,
                                            isHighlight: isHighlight
                                        }
                                    );
                                }

                            }
                        } else {
                            sskRejectList.push(
                                {
                                    rejectedInFormValueVersionID: moduleObject.version.formValueVersionID,
                                    subSectionKpiID: ssk.subSectionKPIID,
                                    refNumber: ssk.refNumber,
                                    rowIndex: null,
                                    columnIndex: null,
                                    kpiDescription: targetKpi?.description,
                                    columnDesc: columnDescription,
                                    answer: answer,
                                    unitMeasure: ssk.unitMeasure,
                                    isBold: bold,
                                    subSectionID: ssk.subSectionID,
                                    sectionID: fromSection?.sectionID,
                                    isHighlight: isHighlight
                                }
                            );
                        }
                    }


                }

                //Ordena subSectionKPIList
                sskRejectList.sort((a, b) => {
                    if (a.sectionID === b.sectionID) {
                        if (a.subSectionID === b.subSectionID) {
                            if (a.rowIndex === b.rowIndex) {
                                return a.subSectionKPIID < b.subSectionKPIID ? -1 : 1;
                            }
                            return a.rowIndex - b.rowIndex;
                        }
                        return a.subSectionID - b.subSectionID;
                    }
                    return a.sectionID - b.sectionID;
                });

            }
        });

        setSsksToReject(sskRejectList);

    }


    const handleReject = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: 'Please specify the reason to reject all',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Comment',
            confirmButtonColor: '#112A61',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                if (setLoadingSection != null) {
                    setLoadingSection(true);
                }
                const objToRequest = {
                    "moduleID": moduleID,
                    "selectedCompanyID": selectedCompanyID,
                    "commentApprover": result.value,
                    "reportYearID": selectedReportYearID,
                    "waiverIdentifier": waiverIdentifier != null ? waiverIdentifier : null
                }

                await Api.post(`FormValue/reject`, objToRequest).then((result) => {
                    success_message("The data was rejected successfully!");
                    loadData();
                    if (setShowFixedHeader != null)
                        setShowFixedHeader(false);
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setLoading(false);
                    if (setLoadingSection != null) {
                        setLoadingSection(false);
                    }
                    if (setShowFixedHeader != null)
                        setShowFixedHeader(false);
                });
            }
        })

    }

    const handleReactive = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: 'Please specify the reason and KPI rejected',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Comment',
            confirmButtonColor: '#112A61',
            showLoaderOnConfirm: true,
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                //setLoading(true);

                const objToRequest = {
                    "moduleID": moduleID,
                    "selectedCompanyID": selectedCompanyID,
                    "commentApprover": result.value,
                    "reportYearID": selectedReportYearID,
                    "waiverIdentifier": waiverIdentifier != null ? waiverIdentifier : null
                }

                await Api.post(`FormValue/reactive`, objToRequest).then((result) => {
                    success_message("The module was reactive successfully!");
                    loadData();
                    if (setShowFixedHeader != null)
                        setShowFixedHeader(false);
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setLoading(false);
                    if (setLoadingSection != null) {
                        setLoadingSection(false);
                    }
                    if (setShowFixedHeader != null)
                        setShowFixedHeader(false);
                });
            }
        })
    }

    const blockLocalApproverRejection = () => {
        return (userObject?.companyID != null && moduleObject?.version?.globalRejectFormValuesList != null && moduleObject?.version?.globalRejectFormValuesList?.length > 0);
    }

    const hasAddZeroesButton = () => {
        return [14, 15, 16, 17, 18].includes(moduleID);
    }

    const handleChecked = (value) => {
        if (value) {
            Swal.fire({
                title: 'Are you sure you have finished reading all the data in this module?',
                showCancelButton: true,
                cancelButtonColor: '#B0B0BB',
                confirmButtonText: 'Confirm',
                confirmButtonColor: '#112A61',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    if (setLoadingSection != null) {
                        setLoadingSection(true);
                    }

                    const objToRequest = {
                        "moduleID": moduleID,
                        "companyID": selectedCompanyID,
                        "formValueVersionID": versionID,
                        "reportYearID": selectedReportYearID,
                        "waiverIdentifier": waiverIdentifier != null ? waiverIdentifier : null
                    }

                    await Api.post(`ReadConsent/new`, objToRequest).then((result) => {
                        success_message("Your consent was successfully saved!");
                        loadData();
                        setShowAgreeConsent(true);
                        if (setShowFixedHeader != null)
                            setShowFixedHeader(false);
                    }).catch((error) => {
                        exceptionNotificationAPI(error);
                        setLoading(false);
                        if (setLoadingSection != null) {
                            setLoadingSection(false);
                        }
                        if (setShowFixedHeader != null)
                            setShowFixedHeader(false);
                    });
                }
                else {
                    document.querySelector('#chkAgree').checked = false;
                    setShowAgreeConsent(false);
                }
            })
        }
        else {
            document.querySelector('#chkAgree').checked = false;
            setShowAgreeConsent(false);
        }

    }

    const sendExport = () => {
        if (selectedReportYearID == null || selectedReportYearID.length == 0) {
            info_message("Select an crop year");
            return;
        }

        setLoading(true);

        var obj = 
        {
            'CompanyID': selectedCompanyID,
            'ReportYearID': selectedReportYearID,
            'WaiverIdentifier': waiverIdentifier
        }


        Api.post(`export/getCurrentWaiver`, obj ,{ responseType: 'blob' })
            .then((response) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // Internet Explorer
                    window.navigator.msSaveBlob(response.data, "Export_Waiver.xlsx");
                } else {
                    // Outros navegadores
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Export_Waiver.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }

                // Atualizar estados de carregamento
                setLoading(false);
            })
            .catch((error) => {
                // Lidar com erros
                exceptionNotificationAPI(error);
            });
    }

    return (
        <Container className="row">
            <div className="col-md-12" style={{ textAlign: 'left' }}>
                {userRoles.includes("RoleInfoHolder") && showButtons && isEditable ? //Info Holder
                    <>
                        {status != 3 && status != 5 && <button className="save-btn" type="submit">SAVE DRAFT</button>}
                        {/* {<button className="save-btn" type="submit">SAVE DRAFT</button>} */}
                        {showSendButton && <button className="send-btn" onClick={(e) => handleSend(e)} type="button">SEND</button>}
                        {moduleObject.data.isHandleAddZeros == true ? <button className="save-btn float-right" onClick={(e) => handleAddZeroes(e, moduleObject, setModuleObject)} type="button">ADD ZEROS</button> : ""}
                    </>
                    : <></>
                }
                {userRoles.includes("RoleInfoHolder") && !showButtons && !isEditable && moduleObject.isActionPlan ? //Info Holder allow to send action plans
                    <>                       
                        {status != 1 && status != 7 && status != 8 && <button className="send-btn" onClick={(e) => handleSaveActionPlans(e)} type="button">SAVE ACTION PLANS</button>}
                    </>
                    : <></>
                }
                {userRoles.includes("RoleApprover") && showButtons && !isEditable && !blockLocalApproverRejection() && //Approver
                    <>
                        <button className="approve-btn" type="button" onClick={(e) => handleApprove(e)}>APPROVE ALL</button>
                        <button className="reject-btn" type="button" onClick={(e) => handleReject(e)}>REJECT ALL</button>
                        <button className="reject-partial-btn" type="button" onClick={(e) => handlePartialReject(e)}>PARTIAL APPROVAL/REJECTION</button>
                    </>
                }
                {userRoles.includes("RoleApprover") && showButtons && !isEditable && blockLocalApproverRejection() && //Approver
                    <>
                        <button className="approve-btn" type="button" onClick={(e) => handleApprove(e)}>APPROVE ALL</button>
                        <button className="reject-partial-btn" type="button" onClick={(e) => handlePartialReject(e)}>PARTIAL APPROVAL/REJECTION</button>
                    </>
                }
                {userRoles.includes("RoleAdmin") && showButtons && !isEditable && //Admin
                    <>
                        <button className="approve-btn" type="button" onClick={(e) => handleReactive(e)}>REACTIVATE</button>
                        <button className="reject-partial-btn" type="button" onClick={(e) => handlePartialReject(e)}>PARTIAL REACTIVATION</button>
                    </>
                }
                {userRoles.includes("RoleReviewer") && showButtons && !isEditable && //Reviewer
                    <>
                        <button className="reject-btn" type="button" onClick={(e) => handleReject(e)}>REJECT ALL</button>
                        <button className="reject-partial-btn" type="button" onClick={(e) => handlePartialReject(e)}>PARTIAL REJECTION</button>
                    </>
                }
                {userRoles.includes("RoleInfoHolder") && moduleObject.version != null && waiverIdentifier != null && //Infoholder export Waiver
                    <>
                       <button className="export-btn" type="button" onClick={() => sendExport()}>EXPORT</button>
                    </>
                }
            </div>
           
            <ModalRejectPartial
                showModalRejectPartial={showModalRejectPartial}
                setShowModalRejectPartial={setShowModalRejectPartial}
                ssksToReject={ssksToReject}
                moduleID={moduleID}
                selectedCompanyID={selectedCompanyID}
                selectedReportYearID={selectedReportYearID}
                waiverIdentifier={waiverIdentifier}
                loadData={loadData}
                setLoading={setLoading}
                setShowFixedHeader={setShowFixedHeader}
                setLoadingSection={setLoadingSection}
                userRoles={userRoles}
            />
        </Container>
    );
}

export default ModuleButtons;
