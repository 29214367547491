import React, { useEffect, useState, useRef } from 'react';
import { GoLinkExternal, GoChevronDown } from 'react-icons/go';
import { Container, Accordion, Card } from './styles';
import Button from '../Button';
import { Link, useHistory } from 'react-router-dom';
import { AccordionCollapse, Overlay, Popover, Spinner } from "react-bootstrap";
import { MdChat } from 'react-icons/md';

import Api, { exceptionNotificationAPI } from '../../services/api';

import TableLoading from '../../components/TableLoading';
import moment from 'moment';
import ModalSelectWaiver from '../ModalSelectWaiver';

const DashboardGlobal = (user) => {
    const history = useHistory();
    const [userObject, setUserObject] = useState(null);

    const [pop1, setPop1] = useState(false);
    const [target1, setTarget1] = useState(null);
    const ref1 = useRef(null);

    const [pop2, setPop2] = useState(false);
    const [target2, setTarget2] = useState(null);
    const ref2 = useRef(null);

    const [pop3, setPop3] = useState(false);
    const [target3, setTarget3] = useState(null);
    const ref3 = useRef(null);

    const [pop4, setPop4] = useState(false);
    const [target4, setTarget4] = useState(null);
    const ref4 = useRef(null);

    const [notStarted, setNotStarted] = useState(0);
    const [partial, setPartial] = useState(0);
    const [final, setFinal] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [statusCard, setStatusCard] = useState(true);
    const [showModalSelectWaiver, setShowModalSelectWaiver] = useState(false);
    const [selectedWaiver, setSelectedWaiver] = useState(null);

    const [companies, setCompanies] = useState([]);
    const [crops, setCrops] = useState([]);
    const [collapseAll, setCollapseAll] = useState(false);
    const [operations, setOperations] = useState([]);
    const [modules, setModules] = useState([]);
    const [modulesFiltered, setModulesFiltered] = useState([]);
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState("");
    const [selectedCrop, setSelectedCrop] = useState("");
    const [selectedModule, setSelectedModule] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedVersion, setSelectedVersion] = useState("");
    const [searchSupplier, setSearchSupplier] = useState('');
    const [selectedAccordionOperation, setSelectedAccordionOperation] = useState("");
    const [loadingFilteredData, setLoadingFilteredData] = useState(true);
    const status = [
        { value: 0, text: "Not Started" },
        { value: 1, text: "Saved as Draft" },
        { value: 4, text: "Sent to Global Approval" },
        { value: 5, text: "Reopened by Global Approver" },
        { value: 7, text: "Approved" },
        { value: 8, text: "Reactivated by Admin" },
        { value: 99, text: "Delayed" }
    ];
    const [statusFiltered, setStatusFiltered] = useState(status);

    const version = [
        { value: 0, text: "Partial" },
        { value: 1, text: "Final" }
    ];

    useEffect(() => {
        if (companies.length > 0) {
            setStatusCard(true);
            setLoadingFilteredData(true);
            if (!collapseAll) {
                if (selectedCompanyIndex.length > 0) {
                    selectedCompanyIndex.map((companyIndex) => {
                        var targetCompany = searchSupplier[companyIndex];
                        var myCompany = searchSupplier.find(x => x.companyID == targetCompany.companyID);
                        var myCompanyIndex = searchSupplier.findIndex(x => x.companyID == targetCompany.companyID);
                        var allCompanies = searchSupplier.filter(x => x.companyID != targetCompany.companyID);

                        if (targetCompany.operations.length == 0 || selectedOperation != null || selectedCrop != null || selectedModule != null || selectedStatus != null || selectedVersion != null) {
                            Api.get(`Dashboard/GetByFilter?selectedCompanyID=${targetCompany.companyID}` +
                                `&selectedOperation=${selectedOperation}&selectedReportYear=${selectedCrop}` +
                                `&selectedModule=${selectedModule}&selectedStatus=${selectedStatus}&selectedVersion=${selectedVersion}`).then((result) => {
                                    if (result && result.data) {
                                        myCompany.operations = result.data.response;
                                        allCompanies.splice(myCompanyIndex, 0, myCompany);
                                        setCompanies(allCompanies);
                                        setSelectedAccordionOperation(result.data.response[0].operationID);

                                        setLoadingFilteredData(false);
                                    }
                                }).catch((error) => {
                                    exceptionNotificationAPI(error);
                                });
                        } else {
                            allCompanies.splice(myCompanyIndex, 0, myCompany);
                            setCompanies(allCompanies);
                            setSelectedAccordionOperation(targetCompany.operations[0].operationID);
                        }
                    })
                }
            } else if (collapseAll) {
                if (selectedCompanyIndex.length > 0) {
                    companies.map((companyIndex, index) => {
                        var targetCompany = searchSupplier[index];
                        var myCompany = searchSupplier.find(x => x.companyID == targetCompany.companyID);
                        var myCompanyIndex = searchSupplier.findIndex(x => x.companyID == targetCompany.companyID);
                        var allCompanies = searchSupplier.filter(x => x.companyID != targetCompany.companyID);

                        if (targetCompany.operations.length == 0 || selectedOperation != null || selectedCrop != null || selectedModule != null || selectedStatus != null || selectedVersion != null) {
                            Api.get(`Dashboard/GetByFilter?selectedCompanyID=${targetCompany.companyID}` +
                                `&selectedOperation=${selectedOperation}&selectedReportYear=${selectedCrop}` +
                                `&selectedModule=${selectedModule}&selectedStatus=${selectedStatus}&selectedVersion=${selectedVersion}`).then((result) => {
                                    if (result && result.data) {
                                        myCompany.operations = result.data.response;
                                        allCompanies.splice(myCompanyIndex, 0, myCompany);

                                        setCompanies(allCompanies);
                                        setSelectedAccordionOperation(result.data.response[0].operationID);

                                        setLoadingFilteredData(false);
                                    }
                                }).catch((error) => {
                                    exceptionNotificationAPI(error);
                                });
                        } else {
                            allCompanies.splice(myCompanyIndex, 0, myCompany);
                            setCompanies(allCompanies);
                            setSelectedAccordionOperation(targetCompany.operations[0].operationID);
                        }
                    })
                }
            }
        }

        if (userObject != null && userObject.selectedReportYearID && userObject.selectedReportYearID != selectedCrop) {
            var targetCrop = crops.find(x => x.reportYearID == selectedCrop);

            userObject.selectedReportYearID = selectedCrop;
            userObject.selectedReportYear = targetCrop ? targetCrop.year : "";
            setSelectedCrop(selectedCrop);
            loadOperations(selectedCrop);
            loadModules(selectedCrop);
            localStorage.setItem('@QualityApp:user', JSON.stringify(userObject));
            setUserObject(userObject);
            document.querySelector("#selectedCropYear").innerHTML = targetCrop ? targetCrop.year : "";
        }
    }, [selectedCompanyIndex, selectedOperation, selectedCrop, selectedModule, selectedStatus, selectedVersion]);

    useEffect(() => {
        setStatusCard(true);
        var notStarted = 0;
        var partial = 0;
        var final = 0;
        var completed = 0;
        if(selectedCompanyIndex.length > 0) {
            selectedCompanyIndex.map((ind) => {
                if(companies[ind]?.operations != null) {
                    companies[ind].operations.map((op) => {
                        notStarted += op.notStarted;
                        partial += op.partial;
                        final += op.final;
                        completed += op.completed;
                    })
                }               
            });
        }
        
        var total = notStarted + partial + final + completed;
        notStarted = (notStarted / total * 100).toFixed(2);
        partial = (partial / total * 100).toFixed(2);
        final = (final / total * 100).toFixed(2);
        completed = (completed / total * 100).toFixed(2);
        setNotStarted(notStarted);
        setPartial(partial);
        setFinal(final);
        setCompleted(completed);
        setStatusCard(false);

        if (selectedCrop != "" || selectedOperation != "" || selectedModule != "")
            filterFiltersOptions(selectedCrop, selectedOperation, selectedModule);
        else
            setModulesFiltered(modules)
    }, [companies]);

    useEffect(() => {
        var userObjectAux = JSON.parse(localStorage.getItem('@QualityApp:user'));

        setUserObject(userObjectAux);
        loadCrops(userObjectAux);
        loadCompanies();
    }, []);

    const loadCompanies = () => {
        if (user.user.groupID != null) {
            Api.get(`Dashboard/getAllCompaniesFilteredByGroup`).then((result) => {
                if (result && result.data) {
                    setCompanies(result.data.response);
                    setSearchSupplier(result.data.response);
                    // setSelectedCompanyIndex([0]);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            Api.get(`Dashboard/getAllCompanies`).then((result) => {
                if (result && result.data) {
                    setCompanies(result.data.response);
                    setSearchSupplier(result.data.response);
                    // setSelectedCompanyIndex([0]);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const loadCrops = (userObjectAux) => {
        Api.get(`Dashboard/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                setCrops(result.data.response);
            }
            if (userObjectAux != null && userObjectAux.selectedReportYearID == null) {
                var crop = result.data.response.find(x => x.reportYear == ("" + new Date().getFullYear()));
                if (crop != null) {
                    setSelectedCrop(crop.reportYearID);
                    loadOperations(crop.reportYearID);
                    loadModules(crop.reportYearID);
                    userObjectAux.selectedReportYearID = crop.reportYearID;
                    userObjectAux.selectedReportYear = crop ? crop.reportYear : "";

                    localStorage.setItem('@QualityApp:user', JSON.stringify(userObjectAux));
                    setUserObject(userObjectAux);
                }
            } else if (userObjectAux != null && userObjectAux.selectedReportYearID) {
                setSelectedCrop(userObjectAux.selectedReportYearID);
                loadOperations(userObjectAux.selectedReportYearID);
                loadModules(userObjectAux.selectedReportYearID);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const redirectBILink = () => {
        window.open("https://app.powerbi.com/groups/me/apps/e31d07d5-fa95-452c-af71-32e38c5f2a36/reports/0aa6b03b-ea0e-47af-a794-81f1982d8305/ReportSectionfbfe9508e1e6e1e28925?ctid=ff9c7474-421d-4957-8d47-c4b64dec87b5&experience=power-bi", "_blank")
    }

    const loadOperations = (reportYearID) => {
        Api.get(`Dashboard/getAllOperationsByYear?reportYearID=${reportYearID}`).then((result) => {
            if (result && result.data) {
                setOperations(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadModules = (reportYearID) => {
        Api.get(`Dashboard/GetAllByReportYear?reportYearID=${reportYearID}`).then((result) => {
            if (result && result.data) {
                setModules(result.data.response);
                setModulesFiltered(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const collapseAllCompanies = () => {
        var accordionItens = document.querySelectorAll('.dashboard .accordion-item button:is(.collapsed)');
        var accordionContent = document.querySelectorAll('.dashboard .accordion-item .accordion-collapse:is(.show)');
        setCollapseAll(false);
        setSelectedCompanyIndex([]);
        accordionItens.forEach(item => {
            item.classList.remove('collapsed');
            accordionContent.forEach(content => {
                content.classList.remove('show');
            })
        })
        document.getElementById('expandAll').disabled = false;
    }

    const expandAllCompanies = async () => {
        var accordionItens = document.querySelectorAll('.dashboard .accordion-item button:not(.collapsed)');
        var accordionContent = document.querySelectorAll('.dashboard .accordion-item .accordion-collapse:not(.show)');
        setCollapseAll(true);
        setStatusCard(true);


        if (companies.length > 0) {
            var selected = [];
            companies.map((c, i) => {
                selected.push(i);
            })
            setSelectedCompanyIndex(selected);
            accordionItens.forEach((item, index) => {
                item.classList.add('collapsed');
                var targetCompany = companies[item.id];
                var myCompany = targetCompany == null ? companies.find(x => x.companyID == targetCompany.companyID) : companies[index];
                var myCompanyIndex = companies.findIndex(x => x.companyID == targetCompany.companyID);
                var allCompanies = companies.filter(x => x.companyID != myCompany.companyID);
                Api.get(`Dashboard/GetByFilter?selectedCompanyID=${targetCompany.companyID}` +
                    `&selectedOperation=${selectedOperation}&selectedReportYear=${selectedCrop}` +
                    `&selectedModule=${selectedModule}&selectedStatus=${selectedStatus}&selectedVersion=${selectedVersion}`).then((result) => {
                        if (result && result.data) {
                            myCompany.operations = result.data.response;
                            allCompanies.splice(myCompanyIndex, 0, myCompany);
                            setCompanies(allCompanies);
                            setSelectedAccordionOperation(result.data.response[0].operationID);
                            setModulesFiltered(result.data.response);
                        }
                    }).catch((error) => {
                        exceptionNotificationAPI(error);
                    });
                accordionContent.forEach(content => {
                    content.classList.add('show');
                })

                document.getElementById('expandAll').disabled = true;
            });
        }
    }



    useEffect(() => {
        if (selectedOperation) {
            setModulesFiltered(modules.filter(m => m.operationID == selectedOperation))
        }
        else {
            setModulesFiltered(modules)
        }

    }, [selectedOperation])

    const filterCompany = (index) => {
        if (selectedCompanyIndex.includes(index)) {
            setSelectedCompanyIndex(selectedCompanyIndex.filter((x) => x != index))
        } else {
            setSelectedCompanyIndex([...selectedCompanyIndex, index])
        }
    }

    const filterFiltersOptions = (cropFilter, operationFilter, moduleFilter) => {
        var statusAux = [];

        companies.map((company) => {
            var operationsAux = company.operations;

            if (cropFilter != "") {
                var cropTarget = crops.find(x => x.reportYearID == cropFilter);

                operationsAux = operationsAux.filter(x => x.reportYearID = cropTarget.reportYearID);
            }

            if (operationFilter != "")
                operationsAux = operationsAux.filter(x => x.operationID = operationFilter);


            if (operationsAux.length > 0) {
                if (operationFilter != "" && operationsAux.length == 1 && selectedStatus == "" && selectedModule == "") {
                    setModulesFiltered(operationsAux[0].modules);
                }
                else
                    setModulesFiltered(modules)

                if (selectedStatus == "") {
                    operationsAux.map((operation) => {
                        if (operation.modules.length > 0) {
                            var modulesAux = operation.modules;

                            if (moduleFilter != "")
                                modulesAux = modulesAux.filter(x => x.moduleID == moduleFilter);

                            if (modulesAux != undefined) {
                                modulesAux.map((module) => {
                                    var statusExists = statusAux.find(x => x.text == module.status);

                                    if (!statusExists && module.statusNumber) {
                                        statusAux.push({
                                            value: module.statusNumber,
                                            text: module.status
                                        });
                                    }
                                })
                            }
                        }
                    });
                }
            }
        });

        if (selectedStatus == "") {
            statusAux = statusAux.sort((a, b) => a.text > b.text ? 1 : -1);
            statusAux.push({
                value: 99,
                text: "Delayed"
            })

            setStatusFiltered(statusAux);
        }
    };

    const onClickModuleRedirect = (operationName, module, companyID, operationID) => {
        var operationNameWords = operationName.split(" ");
        var moduleNameWords = module.description.split(" ");
        var url;

        userObject.selectedCompanyID = companyID;
        localStorage.setItem('@QualityApp:user', JSON.stringify(userObject));


        if (module.isWaiverModule) {
            var obj = {
                module: module,
                operationID: operationID,
                companyID: companyID,
                operationName: operationName,
                description: module.description
            }

            setSelectedWaiver(obj)
            setShowModalSelectWaiver(true)
        } else {
            url = `/report/${operationName.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${module.description.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}`;
            history.push(url, { operationID });
        }
    }

    const onClickButtonCompareVersion = (companyID, reportYear, operationID, moduleID) => {
        history.push(`/compareVersion/companyID=${companyID}&reportYearID=${reportYear}&operationID=${operationID}&moduleID=${moduleID}`);
    };

    const onClickButtonDiscussionDashboard = (companyID, reportYear, operationID, moduleID) => {
        history.push(`/discussionDashboard/companyID=${companyID}&reportYearID=${reportYear}&operationID=${operationID}&moduleID=${moduleID}`);
    };

    const filterSuppliers = (search) => {
        if (search.length === 0) {
            setSearchSupplier(companies);
        } else if (search.length >= 3) {
            const filteredSuppliers = companies.filter((supplier) => {
                return supplier.name.toLowerCase().includes(search.toLowerCase());
            });
            setSearchSupplier(filteredSuppliers);
            collapseAllCompanies();
        }
        collapseAllCompanies();
    }

    const handleCropChange = (event) => {
        setSelectedCrop(event.target.value);
        setSelectedOperation("");
        setSelectedModule("");
        setSelectedStatus("");
        window.location.reload(true)
      };

    return (
        <>
            {
                companies ?
                    <Container>
                        {
                            user.user && user.user.roles && (user.user.roles[0].id === "23D9D409-D7AA-4966-9047-48C04B41F0A1"
                                || (user.user.roles[0].id === "198E176A-E8F5-437C-B6B9-AAB5972099F4" && user.user.companyID == null)
                                || (user.user.roles[0].id === "C1FF151F-F179-4D1F-B1B1-378B9E6D996F" && user.user.companyID == null)) ?
                                <>
                                    <div className="dashboard-header">
                                        <div className="container" style={{ padding: "0" }}>
                                            <div className="row">
                                                <span style={{ fontSize: 11, color: "#7F7F8A", fontWeight: 400, textAlign: "center", paddingBottom: 10 }}>Based on kpi's of expanded companies</span>
                                                <div className="col-md-3 col-sm-12" ref={ref1}>
                                                    {/* <div className="card row pointer"> */}
                                                    <div className="card row">
                                                        <div className="title-col col-md-12">
                                                            <div className="card-title">Not Started</div>
                                                            <div className="card-number">{
                                                                selectedCompanyIndex.length > 0 ?
                                                                    statusCard ?
                                                                        <Spinner variant="info" animation="border" />
                                                                        :
                                                                        notStarted + '%'
                                                                    :
                                                                    0
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3" ref={ref2}>
                                                    {/* <div className="card row pointer"> */}
                                                    <div className="card row">
                                                        <div className="title-col col-md-12">
                                                            <p className="card-title">Partial Approval</p>
                                                            <p className="card-number">{
                                                                selectedCompanyIndex.length > 0 ?
                                                                    statusCard ?
                                                                        <Spinner variant="info" animation="border" />
                                                                        :
                                                                        partial + '%'
                                                                    :
                                                                    0
                                                            }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3" ref={ref3}>
                                                    {/* <div className="card row pointer"> */}
                                                    <div className="card row">
                                                        <div className="title-col col-md-12">
                                                            <p className="card-title">Final Approval</p>
                                                            <p className="card-number">{
                                                                selectedCompanyIndex.length > 0 ?
                                                                    statusCard ?
                                                                        <Spinner variant="info" animation="border" />
                                                                        :
                                                                        final + '%'
                                                                    :
                                                                    0
                                                            }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3" ref={ref4}>
                                                    {/* <div className="card row pointer"> */}
                                                    <div className="card row">
                                                        <div className="title-col col-md-12">
                                                            <p className="card-title">Completed</p>
                                                            <p className="card-number">{
                                                                selectedCompanyIndex.length > 0 ?
                                                                    statusCard ?
                                                                        <Spinner variant="info" animation="border" />
                                                                        :
                                                                        completed + '%'
                                                                    :
                                                                    0
                                                            }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </>
                                :
                                <></>
                        }
                        <div className="container dashboard-body">
                            <div className="dashboard-body-title row">
                                <div className="col-md-12">
                                    <p>Filter</p>
                                </div>
                            </div>
                            <div className="dashboard-body-filters row">
                                <div className="col-md-2">
                                    <select onChange={handleCropChange} value={selectedCrop}>
                                        {
                                            crops ?
                                                crops.map(year => {
                                                    return <option key={year.reportYearID} value={year.reportYearID}>{year.year}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </div>
                                <div className="col-md-10">
                                    <input
                                        type="text"
                                        placeholder="Search Supplier"
                                        onChange={(e) => {
                                            const searchValue = e.target.value;
                                            if (searchValue === '') {
                                                filterSuppliers('');
                                            } else {
                                                filterSuppliers(searchValue);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <select onChange={(x) => { setSelectedOperation(x.target.value); setSelectedModule(""); setSelectedStatus("") }} value={selectedOperation}>
                                        <option value="">All Forms</option>
                                        {
                                            operations && operations.length > 0 ?
                                                operations.map(operation => {
                                                    return <option key={operation.operationID} value={operation.operationID}>{operation.description}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <select onChange={(x) => { setSelectedModule(x.target.value); setSelectedStatus("") }} value={selectedModule}>
                                        <option value="">All Modules</option>
                                        {
                                            modulesFiltered && modulesFiltered.length > 0 ?
                                                modulesFiltered.filter(module => module.isActive === true)
                                                .map(module => {
                                                    return <option key={module.moduleID} value={module.moduleID}>{module.description}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <select onChange={(x) => setSelectedStatus(x.target.value)} value={selectedStatus}>
                                        <option value="">All Status</option>
                                        {
                                            statusFiltered && statusFiltered.length > 0 ?
                                                statusFiltered.map(item => {
                                                    return <option key={item.value} value={item.value}>{item.text}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <select onChange={(x) => setSelectedVersion(x.target.value)} value={selectedVersion}>
                                        <option value="">Partial/Final</option>
                                        {
                                            version && version.length > 0 ?
                                                version.map(item => {
                                                    return <option key={item.value} value={item.value}>{item.text}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </div>
                                <div className="buttons-collapse">
                                    <Button style={{ width: "130px", fontSize: "11px", float: "right", margin: "3rem 1rem 0rem 0" }} id="collapseAll" onClick={collapseAllCompanies}>Collapse All </Button>
                                    <Button style={{ margin: "3rem 1rem 0rem 0", width: "130px", fontSize: "11px", float: "right" }} id="expandAll" onClick={expandAllCompanies}>Expand All </Button>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            {
                                searchSupplier ?
                                    <Accordion className='dashboard'>
                                        {searchSupplier.map((c, i) => {
                                            return <div className="accordion-item" key={`${i}`}>
                                                <h2 className="accordion-header" id={`heading-${i}`}>
                                                    <button className={selectedCompanyIndex.includes(i) ? 'collapsed' : ''} onClick={() => filterCompany(i)} id={`${i}`} type="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls={`collapse-${i}`}>
                                                        <GoChevronDown size={20} />
                                                        <span className="company-name">{c.name}</span>
                                                        {/* <span className={c.percentage == 0 ?
                                                            "status pending"
                                                            :
                                                            "status complete"}>{`${c.percentage}%`}</span> */}
                                                    </button>
                                                </h2>
                                                <div className={`accordion-collapse collapse ${selectedCompanyIndex.includes(i) ? 'show' : ''}`} aria-labelledby={`heading-${i}`} data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        {!loadingFilteredData && c.operations && c.operations.length > 0 ?
                                                            <div>
                                                                <div className="cards-header">
                                                                    <div className="col-md-3">Form</div>
                                                                    <div className="col-md-1" style={{ position: 'relative', left: '-1.5rem' }}>Last change</div>
                                                                    <div className="col-md-2" style={{ paddingLeft: 25 }}>Sent by</div>
                                                                    <div className="col-md-2 year-version-column cropYear" style={{ position: 'relative', left: '-1.5rem', textAlign: 'right' }}>Crop Year</div>
                                                                </div>
                                                                {c.operations.map((operation) => {
                                                                    return <div key={operation.operationID}>
                                                                        <Card className="show" item={operation.operationID}>
                                                                            <div className={`card-body ${selectedAccordionOperation == operation.operationID ? 'collapsed' : ''}`} onClick={() => selectedAccordionOperation == operation.operationID ? setSelectedAccordionOperation(null) : setSelectedAccordionOperation(operation.operationID)}>
                                                                                <div className="col-md-3">
                                                                                    <GoChevronDown />{operation.description}
                                                                                </div>
                                                                                <div className="col-md-2"></div>
                                                                                <div className="col-md-2"></div>
                                                                                <div className="col-md-1 year-version-column">
                                                                                    {operation.year}
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                </div>
                                                                                <div className="col-md-1"></div>
                                                                                <div className="col-md-1">
                                                                                    {
                                                                                        <div className={operation.percentage == 0 ?
                                                                                            "status pending"
                                                                                            :
                                                                                            "status complete"}>
                                                                                            {`${operation.percentage.toFixed(2)}%`}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className={`card collapse ${selectedAccordionOperation == operation.operationID ? 'show' : ''}`}>
                                                                                {operation.modules ?
                                                                                    operation.modules.length > 0 ?
                                                                                        operation.modules.map((module) =>
                                                                                            <div className="card-row">
                                                                                                <div className="card-body" item={module.moduleID} onClick={() => { onClickModuleRedirect(operation.description, module, c.companyID, operation.operationID) }}>
                                                                                                    <div className="col-md-3">
                                                                                                        {module.tobaccoTypeID != null ? module.tobaccoTypeName : module.description}
                                                                                                    </div>
                                                                                                    <div className="col-md-1 last-change-column">
                                                                                                        {module.dateLastChange != null ? module.dateLastChange : ""}
                                                                                                    </div>
                                                                                                    <div className="col-md-2 year-version-column">
                                                                                                        {module.sentBy}
                                                                                                    </div>
                                                                                                    {
                                                                                                        module.deadline != null ?
                                                                                                            <div className={`col-md-2 status ${module.classReject}`}>
                                                                                                                {`Deadline: ${moment(module.deadline, "DD/MM/YYYY").format("DD/MM/YYYY")}`}
                                                                                                            </div>
                                                                                                            : <div className="col-md-2"></div>
                                                                                                    }
                                                                                                    <div className="col-md-1"></div>
                                                                                                    <div className="col-md-2">
                                                                                                        {
                                                                                                            module.status != null &&
                                                                                                            <div className={module.statusNumber == 0 ?
                                                                                                                "status notStarted"
                                                                                                                :
                                                                                                                ((module.statusNumber == 5 || module.statusNumber == 8) ?
                                                                                                                    "status rejected"
                                                                                                                    :
                                                                                                                    "status complete")}>
                                                                                                                {module.status != "" ? module.status : "Not Started"}
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div className="col-md-1">
                                                                                                        {
                                                                                                            <div className={module.percentage == 0 ?
                                                                                                                "status pending"
                                                                                                                :
                                                                                                                "status complete"} style={{ width: '80%' }}>
                                                                                                                {`${module.percentage.toFixed(2)}%`}
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="card-row-buttons-body">
                                                                                                    <button title="Compare Versions" className="card-row-button version-button" onClick={() => onClickButtonCompareVersion(c.companyID, operation.reportYearID, operation.operationID, module.moduleID)}>
                                                                                                        v{module.version}
                                                                                                    </button>
                                                                                                    <button title="Discussion Dashboard" className="card-row-button-last" onClick={() => onClickButtonDiscussionDashboard(c.companyID, operation.reportYearID, operation.operationID, module.moduleID)}>
                                                                                                        <MdChat size={20} />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                        :
                                                                                        <div className="no-record-found">
                                                                                            <div className="col-md-12">No records found</div>
                                                                                        </div>
                                                                                    :
                                                                                    <TableLoading />
                                                                                }
                                                                            </div>
                                                                        </Card>
                                                                    </div>
                                                                })}
                                                            </div>
                                                            :
                                                            <TableLoading />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </Accordion>
                                    :
                                    <TableLoading />
                            }
                        </div>
                        <ModalSelectWaiver
                            showModalSelectWaiver={showModalSelectWaiver}
                            setShowModalSelectWaiver={setShowModalSelectWaiver}
                            companyID={userObject?.selectedCompanyID ?? null}
                            reportYearID={userObject?.selectedReportYearID}
                            module={selectedWaiver}
                            selectedStatus={selectedStatus}
                        />
                    </Container >
                    :
                    <TableLoading />
            }
        </>
    );
}

export default DashboardGlobal;
